.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 1em;
  padding: 1em;
  height: 150px;
  cursor: pointer;
  &:hover {
    border: 2px solid #3383ff;
    .text,
    .plus {
      color: #3383ff;
    }
  }
}
.text,
.plus {
  color: rgb(200, 200, 200);
  &:hover {
    color: #3383ff;
    .container {
      border: 2px solid #3383ff;
    }
  }
}
