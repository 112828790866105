@import '@sass/app.scss';

.requesters-carousel__container {
  width: 100%;
  margin-top: 10px;
}

.requesters-carousel__content {
  .ant-carousel {
    width: 170px;
    margin: 20px auto;
    .slick-dots li button {
      margin-top: 40px;
      background: $primary;
      opacity: 0.7;
    }
  }
}

.requesters-carousel__user-stats {
  margin: 10px;
}

.requesters-carousel__no-requesters {
  display: flex;

  img {
    margin: auto;
    width: 170px;
  }
}
