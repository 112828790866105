@import '@sass/app.scss';

.client-project__chat-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
}

.client-project__chat-wrapper {
  background-color: white;
  padding: 10px;
  border-radius: $card-radius;
  margin-bottom: 10px;
}

.client-project__files-wrapper {
  background-color: white;
  padding: 10px;
  border-radius: $card-radius;
  hr {
    margin: 2px;
  }
}

.client-project__files-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
}

.view-request-drawer__request-breakdown {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0px;
  padding: 15px;

  hr {
    height: 2px;
    width: 80%;
  }
}

.profile-section__text {
  margin: 15px 5px;
  .clamp-lines .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
  }
}
