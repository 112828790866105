.registration-confirmation-input {
  line-height: 30px;
  font-weight: bolder;
  margin-top: 10px;
  width: 80% !important;
  margin-left: 10%;
}
.registration-confirmation-button {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
}
.registration-resend-container {
  display: flex;
  max-width: 80%;
  margin: auto;
  margin-bottom: 15px;
}
.registration-resend-text {
  color: var(--details-text-color);
}
.registration-resend-button {
  margin-left: 5px;
}
.registration-resend-input {
  line-height: 27px;
}
.ant-input-password {
  border-radius: 0.8rem !important;
}
.ant-input {
  border-radius: 0.8rem;
}
.forgot-password-input {
  margin-bottom: 30px;
}
.button-confirm {
  text-align: center;
}
