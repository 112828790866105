.personal-data__container {
  hr {
    margin: 5px 0px 20px 0px;
  }
}

.personal-data__header {
  display: flex;
  h3 {
    margin-bottom: 0px;
  }
  button {
    max-width: 100px;
    margin-left: auto;
    margin-right: 0px;
    margin-top: -10px;
  }
}

.personal-data__row {
  display: flex;
  margin-bottom: 20px;
  p {
    flex: 1;
    margin-bottom: 0px;
  }
  input {
    flex: 1;
    margin-left: 20px;
  }
  .select-gender {
    flex: 1;
    width: 100%;
    .ant-select-selector {
      border-radius: 0.8rem;
    }
  }
  .ant-space {
    flex: 1;
    width: 100%;
    .ant-space-item .ant-picker {
      display: flex;
      border-radius: 0.8rem;
    }
  }
}
