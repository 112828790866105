@import '@sass/app.scss';

.container {
  display: flex;
  height: 90px;
  padding: 10px;
  border-radius: $card-radius;
  border: 1px solid lightgray;
  margin-bottom: 5px;
  position: relative;
}

.rejected {
  pointer-events: none;
  opacity: 0.5;
}

.container:hover {
  background-color: #d5e8ff;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 1em;
}

.imageContainer img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
}

.titleContainer {
  padding-left: 10px;
  margin: auto;
}

.titleContainer p {
  margin: 0px;
}

.activityContainer {
  position: absolute;
  right: -5px;
  top: -2px;
}

.activityItem {
  display: flex;
  background-color: rgba(0, 213, 161, 0.05);
  border-radius: 2rem;
  width: 40px;
  margin-bottom: 10px;
  border: 1px solid rgb(0, 213, 161);
}

.activityItemContent {
  display: flex;
  margin: auto;
}

.activityItemContent svg {
  width: 12px;
  margin: auto;
}

.activityItemContent p {
  margin: auto;
}
