.tooltip-box {
  display: flex;
  z-index: 1;
}
.tooltip-container {
  border: none;
}
.tooltip-background-text {
  background-color: var(--global-color-main);
  position: absolute;
  color: white;
  border-radius: 0.8rem;
  padding: 15px;
  width: fit-content;
  height: fit-content;
  margin: 5px 0px 0px 50px;
  max-width: 200px;
  box-shadow: var(--box-shadow-border);
  text-align: left;
}
