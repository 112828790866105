@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
  max-width: 940px;
  margin: auto;
}

.commentContainer {
  display: flex;
  margin-bottom: 10px;
}

.image img {
  border-radius: 50%;
  width: 40px;
}

.name {
  margin-left: 10px;
}

.inputContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  button {
    margin-left: auto;
    margin-right: 0px;
  }
}
