.billing-information__rut,
.billing-information__direction,
.billing-information__activity {
  display: flex;
  margin-top: 10px;
  p {
    flex: 1;
    margin: 0px;
  }
  input {
    flex: 1;
  }
}
