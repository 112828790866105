@import '@sass/app.scss';
.bank-accounts__container {
  background-color: white;
  border-radius: 0.8rem;
  padding: 20px;
  hr {
    margin: 5px 0px 20px 0px;
  }
}
.bank-accounts__content {
  display: flex;
  flex-wrap: wrap;
  img {
    margin: auto;
    width: 30%;
  }
  @media screen and (max-width: $mobile-xmedium) {
    flex-direction: column;
  }
}
