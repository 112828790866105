@import '@sass/app.scss';

.other-requesters__container {
  hr {
    margin-bottom: 10px;
    margin-top: 2px;
  }
}

.other-requesters__scroll-content {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  max-height: 75vh;
  padding: 1em;
}

.layoutContainer {
  padding: 20px;
}
