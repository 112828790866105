.question-box__container {
  width: 100%;
  display: flex;
}

.question-box__with-answer {
  margin-left: auto;
  margin-right: 0px;
  display: flex;

  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  p {
    margin: 10px;
  }
}

.question-box__without-answer {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  .question-box__button-container {
    margin-left: auto;
    margin-right: 0px;
  }

  .ant-input-textarea {
    width: 100%;
  }
}
