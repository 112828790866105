// Mixin for buttons
@mixin tn-btn {
  font-family: $main-font;
  border-radius: $button-radius;
  letter-spacing: 1px;
  align-content: center;
  justify-content: center;
  margin: 5px 2px;
}
@mixin landing-btn {
  font-family: $main-font;
  border-radius: $button-radius;
  letter-spacing: 1px;
  align-content: center;
  justify-content: center;
  margin: 5px 2px;
}
@mixin goback-hover {
  &:hover {
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25));
  }
}
