//Radius
$button-radius: 2rem;
$card-radius: 0.8rem;

// Main shadow hover for cards
$main-shadow-hover: 3px 3px 35px 3px rgba(0, 0, 0, 0.25);

//Others
$ggs: 1.1;
$header-height: 120px;
