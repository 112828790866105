@import '@sass/app.scss';

.bank-account-drawer__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bank-account-drawer__blue-background {
  background-color: $main;
  height: 60px;
}

.bank-account-drawer__content {
  padding: 0px 24px;
  flex: 1;
  overflow: auto;
  height: auto;
}

.bank-account-drawer__header {
  padding: 10px 24px;
}
