ul {
  padding: 0;
  list-style-type: none;
}
li {
  list-style: none;
}
.card_calendar {
  max-width: 300px;
  min-height: 270px;
}

.month {
  ul {
    display: flex;
    justify-content: center;
  }
  span {
    margin-right: 1em;
  }
}
.arrow {
  cursor: pointer;
}
.current_month {
  margin: 0 1em;
  width: 3em;
  text-align: center;
}
.weekdays,
.days {
  li {
    text-align: center;
    padding: 0.1em;
  }
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.stage_start {
  border: 2px solid #00d5a1;
}
.stage_completed {
  background-color: #00d5a1;
  color: white;
}
.project_start {
  border: 2px solid #3383ff;
}
.project_completed {
  background-color: #3383ff;
  color: white;
}
.stage_start,
.stage_completed,
.project_start,
.project_completed {
  cursor: pointer;
  padding: 0.2em;
  border-radius: 1em;
}
