@import '@sass/app.scss';

.client-publication__content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  @media screen and (max-width: $desktop-large) {
    grid-template-columns: 1fr;
  }
}

.client-publication__section {
  flex: 1;
}

.client-publication__deactivate {
  pointer-events: none;
  opacity: 0.6;
}

.layoutContainer {
  padding: 0px;
  @media (max-width: $desktop-small) {
    padding: 20px;
  }
}
.postulantes_custom_button {
  display: none;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 99;
  background-color: #3383ff;
  color: white;
  padding: 0.7em 0.5em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  cursor: pointer;
  .ant-badge {
    position: absolute;
    top: -5px;
    left: -5px;
  }
  @media (max-width: $desktop-small) {
    display: block;
  }
}
