@import '@sass/app.scss';

.dropdown-menu__container {
  position: absolute;
  top: 60px;
  width: 330px;
  transform: translateX(-75%);
  background-color: $secondary-back;
  overflow: hidden;
  border: 1px solid rgb(201, 199, 199);
  border-radius: $card-radius;
  z-index: 3;
  padding: 5px;
  h3 {
    margin: 10px 0px 0px 10px;
  }
  hr {
    margin-bottom: 0px;
  }
}

.dropdown-menu__content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-height: 200px;
  max-height: 55vh;
  width: 100%;
  img {
    width: 60%;
    margin: 10px auto;
  }
}

.dropdown-menu__content::-webkit-scrollbar {
  display: none;
}

.dropdown-menu__show-more {
  display: flex;
  justify-content: flex-end;
  padding: 5px 20px 10px 0;
  margin-top: 20px;
  background-color: var(--background-color-secondary);
  button {
    border: none;
    font-size: 14px;
  }
}
