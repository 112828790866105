.container {
  display: flex;
  flex-wrap: wrap;
}

.category {
  display: flex;
  background-color: white;
  border-radius: 1em;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.button {
  cursor: pointer;
  margin-left: 5px;
}
