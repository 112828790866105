@import '@sass/app.scss';
.file__button-container {
  display: flex;
  justify-content: flex-end;
}
.file__card {
  width: 200px;
  margin: auto;
  .file__inputs {
    position: relative;
    margin: 10px 0px 0px 0px;
    input {
      position: relative;
      max-width: 200px;

      height: 46px;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
    }
    button {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      color: #fff;
      background-color: $primary;
      font-size: 0.8rem;
      cursor: pointer;
      border-radius: 2em;
      border: none;
      outline: none;
      transition: background-color 0.4s;
      box-shadow: 0px 8px 24px $main-shadow-hover;

      i {
        width: 2em;
        height: 2em;
        padding: 0.4em;
        background-color: $secondary-back;
        color: $primary;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.8em;
        font-size: 0.8rem;
      }
    }
    &:hover {
      button {
        background-color: $primary;
      }
    }
  }
}
