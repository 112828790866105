@import '@sass/app.scss';

.footer-container {
  position: relative;
  bottom: 0px;
  width: 100%;
}

body {
  background: #fbfbfd;
}

.new_footer_area {
  background: #fbfbfd;
}

.new_footer_top {
  padding: 60px 0px 350px;
  position: relative;
  overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 30px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none !important;
  outline: none;
}

.f_list li a:hover {
  text-decoration: underline !important;
  color: $main;
}
.f_list li {
  margin-bottom: 11px;
}
.f_list li:last-child {
  margin-bottom: 0px;
}
.f_list li {
  margin-bottom: 15px;
}
.f_list {
  margin-bottom: 0px;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: '\e741';
}
.ti-twitter-alt:before {
  content: '\e74b';
}
.ti-vimeo-alt:before {
  content: '\e74a';
}
.ti-pinterest:before {
  content: '\e731';
}

.form-control.memail {
  border-radius: 0.8rem;
}

.btn_get {
  font-size: 14px !important;
  color: #6a7695 !important;
  border-radius: 0.8rem !important;
  border-color: lightgrey !important;
  &:hover {
    color: #fff !important;
    background-color: $main;
    border-color: $main !important;
  }
}

.new_footer_top .f_social_icon a:hover {
  background: $main;
  border-color: $main;
  color: white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 15px;
}
.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
.contact-mobile {
  display: none;
}

@media screen and (max-width: $mobile-large) {
  .contact-desktop {
    display: none;
  }
  .contact-mobile {
    display: inline;
  }
}
.info-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: $mobile-large) {
    padding: 0 30px;
  }
  .marg-20 {
    margin-bottom: 20px;
  }
  .newsletter-btn {
    margin-top: 20px;
  }
}
.first-column-container {
  min-width: 350px;
  padding: 0 30px;
  @media screen and (max-width: $mobile-large) {
    margin-bottom: 30px;
  }
  a {
    color: #263b5e;
  }
}
.how-to-guide-column-container {
  min-width: 300px;
  padding: 0 30px;
  @media screen and (max-width: $mobile-large) {
    margin-bottom: 30px;
  }
}
.second-column-container {
  min-width: 300px;
  padding: 0 30px;
  @media screen and (max-width: $mobile-large) {
    margin-bottom: 30px;
  }
}
.third-column-container {
  min-width: 350px;
  padding: 0 30px;
}
.new_footer_top .f_list li a {
  color: #6a7695;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  background-image: url(../../../../assets/images/footer_official.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: auto 100%;
  width: 100%;
  min-height: 266px;
  height: 356px;
  overflow: hidden;
  @media screen and (max-width: $mobile-large) {
    width: 100%;
  }
}

.new_footer_top .footer_bg .footer_bg_one {
  background: url(../../../../assets/images/gif_auto_official.gif) no-repeat center center;
  width: 180px;
  height: 100%;
  background-size: 100%;
  position: absolute;
  top: 88px;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
  @media screen and (max-width: $mobile-large) {
    -webkit-animation: myfirst 10s linear infinite;
    animation: myfirst 10s linear infinite;
    width: 120px;
  }
}

.new_footer_top .footer_bg .footer_bg_two {
  background: url(../../../../assets/images/cyclist.gif) no-repeat center center;
  width: 88px;
  height: 100px;
  background-size: 100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
  @media screen and (max-width: $mobile-large) {
    -webkit-animation: myfirst 15s linear infinite;
    animation: myfirst 15s linear infinite;
    width: 80px;
  }
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}
.footer_sponsors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em 1em;
  img {
    object-fit: cover;
  }
}
