@import '@sass/app.scss';

.tn-title {
  font-family: $main-font;
  &.landing {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 48px;
    }
  }
  &.xxl {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xlarge) {
      font-size: 22px;
    }
  }
  &.xl {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 1px;
    @media (max-width: $desktop-small) {
      font-size: 18px;
    }
  }
  &.primary {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 17px;
    }
  }
  &.primary-nobold {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 17px;
    }
  }
  &.secondary {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 15px;
    }
  }
  &.secondary-bold {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 15px;
    }
  }

  &.tertiary {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.8px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 13px;
    }
  }
  &.subtitle {
    font-size: 15px;
    font-family: $main-font-medium;
    color: $primary-text;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 14px;
    }
  }
  &.quaternary {
    font-size: 10px;
    font-family: $main-font-light;
    color: $primary-text;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 9px;
    }
  }
  &.accountTitle {
    letter-spacing: 1px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
  }
  &.boardDashboard {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 1px;
    text-align: center;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 15px;
    }
  }
  &.footer {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
    letter-spacing: normal;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 17px;
    }
  }
  &.legalFiles {
    margin-bottom: 15px;
    font-size: 20px;
    font-family: $main-font;
    letter-spacing: normal;
    color: $grey-font;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 19px;
    }
  }

  &.primaryColor {
    color: $primary;
  }
  &.secondaryColor {
    color: $secondary;
  }
  &.mainColor {
    color: $main;
  }
  &.whiteColor {
    color: white;
  }
  &.blackColor {
    color: black;
  }
  &.detailColor {
    color: $details;
  }
  &.primaryTextColor {
    color: $primary-text;
  }
  &.secondaryTextColor {
    color: $details-text;
  }
  &.center {
    margin: auto;
  }
}
