.container {
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.image {
  border-radius: 50%;
  max-width: 30px;
  max-height: 30px;
}

.header {
  align-items: center;
  display: flex;
  width: 100%;
}

.body {
  margin: 2px 0 2px;
  width: 100%;
}

.right {
  margin: 5px 10px 0;
}
