@import '@sass/app.scss';

.widget_container {
  display: flex;
  gap: 24px;
  align-items: flex-end;
  position: fixed;
  bottom: 0px;
  right: 100px;
  z-index: 99;
  font-family: $main-font-light;

  @media screen and (max-width: $desktop-medium) {
    right: 30px;
  }
}
.blue_circle {
  height: 12px;
  width: 12px;
  background-color: #0181ff;
  border-radius: 50%;
}
.white_circle {
  height: 12px;
  width: 12px;
  background-color: #00d5a1;
  border-radius: 50%;
}

.chat_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #0181ff;
  border-radius: 5px 5px 0px 0px;
  min-width: 180px;
  max-width: 320px;
  height: 50px;
  cursor: pointer;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.chat_header_title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  img {
    width: 32px;
    border-radius: 100%;
  }
}
.chat_content_main {
  height: 320px;
  min-width: 320px;
  padding: 8px 12px;
  overflow-y: auto;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.chat_content {
  height: 320px;
  width: 320px;
  padding: 8px 12px;
  overflow-y: auto;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  flex-direction: column-reverse;
}
.chat_content_title {
  font-weight: 700;
}
.chat_items_list {
  display: flex;
  flex-direction: column;
}
.chat_item {
  padding: 8px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all;

  img {
    width: 38px;
    border-radius: 100%;
  }
  &:hover {
    background-color: #f2f2f2;
  }
}
.chat_search {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 8px 16px;
  height: 50px;
  max-width: 320px;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  input {
    border: 0;
    outline: 0;
    background-color: #f2f2f2;
    width: 100%;
  }
}
.chat_search_items {
  display: flex;
  gap: 4px;
  color: #3383ff;
}
.message_sent_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
  word-break: break-word;
  font-size: 12px;
}
.message_received_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  word-break: break-word;
  font-size: 12px;
}
.message_sent {
  background-color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 10px;
}

.message_received {
  background-color: #0181ff;
  padding: 4px 8px;
  color: white;
  border-radius: 10px;
}
.messages {
  display: flex;
  flex-direction: column-reverse;
}

.underline_hover {
  &:hover {
    text-decoration: underline;
  }
}
.unreaded_msgs {
  position: absolute;
  background-color: #ff4d4f;
  color: white;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  top: 2px;
  right: 0;
}

.unreaded {
  background-color: #ff4d4f;
  color: white;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}
