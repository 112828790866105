@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid $main;
  min-height: 340px;
}

.content {
  display: flex;
  flex-direction: column;
}
.image {
  margin-bottom: 20px;
  img {
    width: 100%;
    border-radius: $card-radius;
    object-fit: cover;
  }
}
.primaryTitle {
  display: flex;
}
.secondaryTitle {
  display: flex;
  min-height: 80px;
}
.icons {
  color: lightgray;
}

.text {
  overflow: hidden;
  margin-bottom: 10px;
  p {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Poppins Light';
    font-size: 1rem;
  }
}

.button {
  margin-top: 10px;
  display: flex;
  button {
    margin: 0px 0px 0px auto;
    width: 150px;
  }
  .date {
    margin-top: auto;
    margin-bottom: auto;
  }
}
