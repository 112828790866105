@import '@sass/app.scss';

.container {
  border-radius: 1em;
  margin: 10px 10px 0 0;
  max-width: 1024px;
  width: 100%;
  background-color: white;
  padding: 20px;
  @media screen and (max-width: $mobile-xlarge) {
    margin: 10px 0px 0px 0px;
  }
}
.serviceCardOwner {
  border-radius: 0 0 1em 1em !important;
  cursor: pointer;
  margin-bottom: 20px !important;
}
.serviceCard {
  border-radius: 1em !important;
  cursor: pointer;
  margin-bottom: 20px !important;
}
.greenHeader {
  background-color: $secondary;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  display: flex;
}
.show_more {
  color: $secondary;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: $secondary;
    text-decoration: underline;
  }
}
