@import '@sass/app.scss';

.completed-projects__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    margin-bottom: 20px;
  }
}

.completed-projects__header {
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    flex: 1;
    font-size: 12px;
    .ant-rate-star {
      margin-right: 2px;
    }
  }
  p {
    flex: 3;
    margin: 0px;
  }
}

.completed-projects__rate {
  display: flex;
  ul {
    margin-right: 0px;
    margin-left: auto;
  }
}
.completed-project__comment p {
  margin: 0px;
}

.completed-projects__project {
  border-radius: 0.8rem;
  padding: 10px;
  width: 100%;
  min-height: 60px;
  margin-bottom: 5px;
}

.completed-projects__project:hover {
  background-color: rgba(128, 128, 128, 0.05);
}
