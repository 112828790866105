@import '@sass/app.scss';

.container {
  width: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.images {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.empty {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 120px;
  height: 90px;
  display: flex;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  margin: auto;
  img {
    max-width: 90px;
    max-height: 50px;
    margin: auto;
  }
}

.images > div {
  margin: 5px;
}
.images > div > img {
  border: 2px solid $main;
}

.section {
  margin-top: 15px;
}
