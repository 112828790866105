/* COLOR AZUL PRINCIPAL  rgb(51, 131, 255)
    COLOR VERDE SERVICIOS rgb(0, 213, 161)
*/
@import '@sass/app.scss';
:root {
  //--global-color-main: #19acd0;
  --global-color-main: rgb(51, 131, 255);
  --global-color-main-traslusent: rgba(51, 131, 255, 0.05);
  --global-color-secondary: rgb(0, 213, 161);
  --global-color-secondary-traslusent: rgba(0, 213, 161, 0.05);
  --primary-color: rgb(51, 131, 255);
  --warning-color: #ff0000;
  --complete-color: rgb(0, 213, 161);

  --details-text-color: #7e7f80;
  --primary-text-color: rgb(27, 27, 27);
  --disabled-color: #fad6d6;
  --background-color-light: #fff;
  --background-color: rgb(235, 235, 235);
  --popup-background-color: rgba(84, 84, 84, 0.39);

  --box-shadow-border: 0px 0px 15px 1px rgba(71, 23, 23, 0.3);
  --menu-hover: #dbdbdb;

  --primary-font-family: 'Semi Bold', serif;
  --secondary-font-family: 'Std Light', serif;
  --subtitle-font-size: 20px;
  --text-font-size: 17px;
  --detail-font-size: 14px;
  --title-font-size: 25px;
  --border-radius: 20px;
  --max-width-layout: 1000px;

  --ggs: 1.1;
  --header-height: 120px;
}

button {
  border: none;
  outline: 0;
  background: transparent;
  border-radius: 5px;
  color: $main;
  transition: all ease 0.8s;
  cursor: pointer;
}

button:focus {
  outline: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-default {
  background-color: #253556;
  border-color: #e7e7e7;
}

nav .navbar-nav li {
  color: white !important;
  font-size: 25px;
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  margin: auto;
}

.custom-container {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 auto;
  max-width: 1350px;
  @media screen and (max-width: $mobile-large) {
    width: 100%;
    max-width: 100%;
  }
}
.grey-background {
  background-color: var(--background-color);
  min-height: calc(100vh - 70px);
  min-width: 100%;
}
.ant-card-body {
  width: 100%;
}

.main-color {
  color: $main;
}

/* CUSTOM COMPONENTS */

.simple-button {
  background-color: #fff;
  color: var(--primary-color);
  border-radius: 0.8rem;
  border-color: var(--primary-color);
  max-width: 100px;
  outline: 0 !important;
  font-family: $main-font;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 2px;
  transition: background-color 300ms;
}

.simple-button:hover {
  background-color: var(--background-color);
  transform: scale(1.02);
}

.simple-button:active {
  transform: translateY(2px);
}

.centered-item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7%;
}
.centered-icon {
  transform: translateY(-30%);
}
.basic-input {
  width: 100%;
  border: none;
  background-color: var(--background-color);
  border-radius: 0.8rem;
  padding-left: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.basic-input:focus {
  outline: none !important;
  border-radius: 0.8rem;
  border: none;
}
.basic-container {
  border-radius: 0.8rem;
  padding: 15px;
  background-color: #fff;
}
.basic-container-shadow {
  border-radius: 0.8rem;
  background-color: #fff;
}
.basic-container-shadow-filter {
  border-radius: 0.8rem;
  background-color: #fff;
}
.basic-container-shadow:hover {
  box-shadow: $main-shadow-hover;
}
.basic-container-shadow-border {
  box-shadow: var(--box-shadow-border);
}
.centered-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.animated-button {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.animated-button {
  border-color: var(--primary-color);
  font-family: $main-font;
  font-size: 20px;
  color: white;
  font-weight: bolder;
  letter-spacing: 3px;
  background-color: var(--primary-color);
  border-radius: 0.7rem;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0 5px #999;
}
.animated-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.animated-button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.animated-button:hover {
  background-color: rgb(87, 124, 245);
}

.animated-button:hover span {
  padding-right: 25px;
}

.animated-button:hover span:after {
  opacity: 1;
  right: 0;
}
.window-container {
  font-family: $main-font-light;
  color: var(--primary-text-color);
  font-weight: bold;
  position: relative;
  height: 100%;
}
.window-line {
  display: flex;
  margin-bottom: 8px;
}
.close-btn {
  height: 40px;
  width: 40px;
  font-size: 20px;
  color: #031d2c;
  border: none;
  border-radius: 25px;
}

.close-btn:hover {
  background-color: #dcdcdc;
}
.close-btn-ctn {
  display: flex;
  justify-content: flex-end;
}
.lg-circular-img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.loading-spin {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.spinner-container {
  width: 100%;
  height: 100%;
}
.ant-input-number {
  border-radius: 0.8rem;
}
.flex-container {
  margin: auto auto 50px auto;
  display: flex;
  max-width: 1800px;
  min-height: 400px;
}
.flex-container-left-side {
  flex: 1 3 20%;
}
.flex-container-mid-side {
  flex: 3 1 60%;
  position: relative;
}
.flex-container-right-side {
  flex: 1 3 20%;
}
.UnauthorizedCtn {
  background-image: url(../images/401.jpg);
  background-size: cover;
  background-position: top;
  position: relative;
  border-radius: 0.8rem;
  height: 500px;
  width: 500px;
  margin: auto;
}

.h7 {
  font-family: $main-font;
  font-size: 12px;
  color: gray;
  font-weight: bold;
  letter-spacing: 0.2px;
}
.ant-message-notice-content {
  border-radius: 0.8rem;
  border: 1px solid gray;
  svg {
    margin-bottom: 5px;
  }
}
.entering-transition {
  animation: moveInRight 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

@media screen and (min-width: $desktop-small) {
  .management__title {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  80% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes enterIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
    transform: scale(1.02);
  }

  80% {
    opacity: 0.8;
    transform: scale(1.04);
  }

  100% {
    opacity: 1;
  }
}
