@import '@sass/app.scss';

.content {
  display: flex;
  @media screen and (max-width: $desktop-large) {
    flex-direction: column;
  }
}

.section {
  flex: 1;
}
