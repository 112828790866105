@import '@sass/app.scss';

/*Generic button*/
.tn-btn {
  @include tn-btn;
  /*Primary button*/
  &.--primary {
    color: $secondary-back;
    background-color: $main;
    font-size: 18px;
    padding: 0.375rem 0.75rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;
    &.--xs {
      font-size: 11.4px;
    }
    &.--sm {
      font-size: 14px;
    }
    &.--lg {
      font-size: 22px;
    }
    &:hover {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
      filter: brightness(1.2);
    }
    &.--primaryColor {
      background-color: $primary;
      &:hover {
        filter: brightness(1.2);
      }
    }
    &.--secondaryColor {
      background-color: $secondary;
      font-weight: normal;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &.--warningColor {
      background-color: $warning;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &.--white-color {
      background-color: var(--background-color-light);
      color: $main;
      font-weight: normal;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &.--disabled {
      color: rgba(0, 0, 0, 0.3);
      background-color: lightgray;
      border: 1px solid lightgray;
      cursor: default;
      &:hover {
        box-shadow: none;
        filter: none;
      }
    }
  }
  /*Secondary button*/
  &.--secondary {
    color: rgba(0, 0, 0, 0.6);
    background-color: $secondary-back;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.375rem 0.75rem;
    font-size: 10px;
    min-width: 130px;
    transition: color 100ms, border-color 100ms;
    &.--sm {
      font-size: 14px;
    }
    &.--lg {
      font-size: 19px;
    }
    &:hover {
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
      color: $main;
      border-color: $main;
    }
    &.--primaryBorderColor {
      border-color: $primary;
      color: $primary;
    }
    &.--secondaryBorderColor {
      border-color: $secondary;
      color: $secondary;
    }

    &.--errorBorderColor {
      border-color: red;
      color: red;
    }
    &.--primaryColor {
      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
    &.--secondaryColor {
      &:hover {
        border-color: $secondary;
        color: $secondary;
      }
    }
    &.--warningColor {
      &:hover {
        border-color: $warning;
        color: $warning;
      }
    }

    &.--evaluationColor {
      color: $secondary-back;
      background-color: $main;
      border-color: $main;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &.--disabled {
      color: rgba(0, 0, 0, 0.3);
      background-color: lightgray;
      border: 1px solid lightgray;
    }
  }
  /*Profile button for postulate a proyect*/
  &.--profile {
    @extend .--primary;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    min-width: 150px;
    &.--disabled {
      color: rgba(0, 0, 0, 0.3);
      background-color: lightgray;
      border: 1px solid lightgray;
    }
  }
  /*Category button for profile image edition*/
  &.--category {
    color: rgba(0, 0, 0, 0.8);
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    min-width: 150px;
    width: 100%;
    min-height: 70px;
    border: none;
    transition: background-color 300ms;
    &:hover {
      background-color: lightgray;
    }
    &.--active {
      background-color: lightgray;
    }
  }
  /*Go back button*/
  &.--goback {
    color: rgba(0, 0, 0, 0.6);
    background-color: $secondary-back;
    border: none;
    clip-path: polygon(100% 0%, 80% 50%, 100% 100%, 25% 100%, 5% 50%, 25% 0%);
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    min-width: 80px;
    transition: color 100ms, border-color 100ms;
    &.--sm {
      font-size: 11px;
    }
    &.--lg {
      font-size: 18px;
    }
  }
  /*Link button*/
  &.--link {
    color: rgba(0, 0, 0, 0.8);
    border: none;
    font-size: 15px;
    min-width: 150px;
    transition: color 100ms;
    &.--sm {
      font-size: 11px;
    }
    &.--lg {
      font-size: 18px;
    }
    &.--mainColor {
      color: $main;
    }
    &.--primaryColor {
      color: $primary;
    }
    &.--secondaryColor {
      color: $secondary;
    }
    &:hover {
      color: rgba(0, 0, 0, 0.3);
    }
  }
  &.--whatsapp {
    @extend .--primary;
    border-radius: $button-radius;
    height: 60px;
    width: 300px;
    font-size: 30px;
    font-family: $main-font-medium;
    font-weight: normal;
  }
  &.--sesion {
    @extend .--primary;
    border-radius: 2rem;
    height: 35px;
    width: 250px;
    font-size: 15px;
    font-family: $main-font-medium;
    font-weight: normal;
    background-color: $primary;
  }
  &.--edit {
    background-color: var(--background-color);
    border-radius: 50%;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.8);
    i {
      font-size: 15px;
      margin: 3px;
    }
    i:hover {
      font-size: 18px;
    }
  }
  //Upload Files button
  &.--files {
    color: $primary-text;
    background-color: $secondary-back;
    border: 1px solid $secondary-button-border;
    padding: 0.3rem;
    font-size: 12px;
    min-width: 130px;
    transition: color 100ms, border-color 100ms;
    &.--sm {
      font-size: 11px;
      padding: 0.3rem;
    }
    &.--lg {
      font-size: 19px;
    }
    &:hover {
      box-shadow: $box-shadow;
      color: $main;
      border-color: $main;
    }
    &.--primaryBorderColor {
      border-color: $primary;
      color: $primary;
    }
    &.--secondaryBorderColor {
      border-color: $secondary;
      color: $secondary;
    }

    &.--errorBorderColor {
      border-color: red;
      color: red;
    }
    &.--primaryColor {
      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
    &.--secondaryColor {
      &:hover {
        border-color: $secondary;
        color: $secondary;
      }
    }
    &.--warningColor {
      &:hover {
        border-color: $warning;
        color: $warning;
      }
    }

    &.--evaluationColor {
      color: $secondary-back;
      background-color: $main;
      border-color: $main;
      &:hover {
        filter: brightness(1.1);
      }
    }
    &.--disabled {
      color: rgba(0, 0, 0, 0.3);
      background-color: lightgray;
      border: 1px solid lightgray;
    }
  }
}
.landing-btn {
  @include landing-btn;

  &.--primary {
    color: $main;
    background-color: var(--background-color-light);
    font-size: 18px;
    padding: 0.375rem 3.5rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;

    &.--primaryColor {
      background-color: $main;
      color: var(--background-color-light);
    }
  }
  &.--secondary {
    color: $secondary-back;
    background-color: $secondary;
    font-size: 18px;
    padding: 0.375rem 3.5rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;
  }

  &.--freelancer {
    color: $main;
    background-color: var(--background-color-light);
    font-size: 18px;
    padding: 0.375rem 1.738rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;
  }

  &.--primaryProject {
    color: $main;
    background-color: var(--background-color-light);
    font-size: 18px;
    padding: 0.375rem 0.625rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;
    width: 45%;

    &.--primaryColor {
      background-color: $main;
      color: var(--background-color-light);
    }
    @media screen and (max-width: $desktop-small) {
      width: 75%;
    }
  }

  &.--secondaryFreelancer {
    color: $secondary-back;
    background-color: $secondary;
    font-size: 18px;
    padding: 0.375rem 0.625rem;
    border: 1px solid transparent;
    transition: filter 300ms;
    font-weight: normal;
    width: 45%;

    @media screen and (max-width: $desktop-small) {
      width: 75%;
    }
  }
}
