@import '@sass/app.scss';

.documents-ctn {
  max-width: 100%;
  margin: auto;
}
.documents-ctn h2 {
  font-family: $main-font;
  color: $main;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 25px;
}
.documents-ctn .ant-collapse-header {
  font-family: $main-font;
  text-transform: uppercase;
  font-size: 13px;
}
.documents-ctn .ant-collapse-content-box {
  padding: 20px 50px;
  text-align: justify;
  text-justify: inter-word;
}
.ant-collapse {
  border-radius: 0.8rem;
  margin-bottom: 2.5rem;
}
p {
  font-family: $main-font;
  font-size: 1rem;
}
.te-necesito {
  color: $main;
}
