@import '@sass/app.scss';

.view-profile__scroll-container {
  padding: 0px 24px;
}

.view-profile__text-section:not(:first-child) {
  margin-top: 40px;
}

.view-profile__text-section {
  .clamp-lines p {
    white-space: pre-wrap;
    font-size: 0.8rem;
  }
  .secondary-view-more .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
    color: $secondary;
  }
  .primary-view-more .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
    color: $primary;
  }
}

.view-profile__social-media {
  display: flex;
  margin-top: 10px;
  width: 100%;
}

.view-profile__link-container {
  flex: 1;
  display: flex;
  a {
    border: 0;
    font-size: 15px;
    color: $details-text;
    display: flex;
    margin: auto;
    i {
      margin: auto 5px;
    }
    p {
      margin: auto;
      margin-top: 3px;
      font-size: 12px;
    }
  }
}

#view-profile__briefcase {
  font-size: 8px;
}

#view-profile__linked {
  color: black;
  font-size: 18px;
  p {
    color: black;
  }
}

#view-profile__linked:hover > i {
  color: $primary;
}

#view-profile__linked:hover > p {
  color: $primary;
}

#view-profile__linked-briefcase {
  color: black;
  font-size: 15px;
  p {
    color: black;
  }
}
#view-profile__linked-briefcase:hover > i {
  color: $primary;
}

#view-profile__linked-briefcase:hover > p {
  color: $primary;
}

.view-profile__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.view-profile__blue-background {
  background-color: $main;
  height: 60px;
}

.view-profile__green-background {
  background-color: $secondary;
  height: 60px;
}

.view-profile__header {
  padding: 5px 24px;
}

.view-profile__content {
  flex: 1;
  overflow: auto;
  height: auto;
}
