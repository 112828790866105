.summary-request__container {
  display: flex;
  height: 80px;
  padding: 10px;
}

.summary-request__image {
  flex: 1;
  display: flex;
}

.summary-request__profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
}

.summary-request__title {
  flex: 3;
  padding-left: 10px;
  margin: auto;
  p {
    margin: 0px;
  }
}

.summary-request__selected {
  background-color: #d5e8ff;
  border-radius: 0.8rem;
}
