@import '@sass/app.scss';

.about__us-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
  width: 100%;
  @media screen and (max-width: $desktop-small) {
    flex-direction: column;
  }
}
.aboutus__left {
  flex: 0 1 500px;
  background-color: $main;
  border-radius: 1rem;
  padding: 20px;
  @media screen and (max-width: $desktop-small) {
    max-width: 450px;
  }
}
.aboutus__right {
  flex: 0 1 360px;
}
.contactus__form {
  margin-top: 20px;
}
.submit__btn {
  background-color: $secondary;
  color: $secondary-back;
  height: 40px;
  padding: 0px 20px;
  font-size: 16px;
  font-family: $main-font;
  align-self: flex-end;
  border-radius: 2rem;
}
.social__media {
  width: 100%;
}
.social__upper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.social__low {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}
.social__icon {
  border-radius: 1rem;
  border: 2px solid lightgray;
  height: 100px;
  margin-right: 15px;
  width: 100px;

  &:hover {
    cursor: pointer;
  }
}
.upper__icon {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 30px;
  }
}
.lower__icon {
  height: 30%;
  text-align: center;
}
