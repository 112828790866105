@import '@sass/app.scss';
.content {
  display: flex;
  flex-direction: column;
}

.paginationContainer {
  margin: auto;
}
.layoutContainer {
  @media screen and (max-width: $desktop-small) {
    padding: 20px;
  }
}
