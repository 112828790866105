@import '@sass/app.scss';
.section3__container {
  min-height: 550px;
  background-color: #fbfbfb;
  padding-top: 100px;
}
.section3__content {
  max-width: var(--max-width-layout);
  width: 95%;
  min-height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.section3__subtitle {
  margin-bottom: 50px;
}

.section3__left {
  flex: 0 1 60%;
}
.section3__right {
  flex: 0 1 500px;
  text-align: center;
}
@media screen and (max-width: $desktop-xsmall) {
  .section3__content {
    flex-direction: column;
  }
  .section3__right {
    margin-top: 50px;
    flex: 0 1 350px;
  }
}
