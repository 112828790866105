@import '@sass/app.scss';

.container {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: $card-radius;
  height: 280px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: $mobile-large) {
    display: none;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 50%;
  padding: 2em 0 3em 2em;

  @media (max-width: 1200px) {
    width: 60%;
    h1 {
      font-size: 20px !important;
    }
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: $card-radius;
  height: 100%;
}
