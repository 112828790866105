@import '@sass/app.scss';

.container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.item {
  border-radius: 2em;
  padding: 2px 20px;
  margin: 3px 4px 3px 0px;
  background-color: $primary-back;
  p {
    margin: 0px;
  }
}
