@import '@sass/app.scss';

.social-media-links__container {
  display: flex;
  width: 100%;
}

.social-media-links__item {
  flex: 1;
  display: flex;
  a {
    border: 0;
    font-size: 15px;
    color: $details-text;
    display: flex;
    margin: auto;
    i {
      margin: auto 5px;
      &:hover {
        scale: 1.05;
      }
    }
    p {
      margin: auto 0px;
    }
  }
}

#social-media-icon {
  color: black;
  font-size: 18px;
  p {
    color: black;
  }
}

#social-media-briefcase {
  color: #3383ff;
  font-size: 13px;
  p {
    color: black;
  }
}
