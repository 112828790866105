.payment-method-option__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  .tn-text {
    margin: auto;
  }

  button {
    margin: 10px auto;
  }
}

.payment-method-option__icon {
  margin: auto;
  width: 120px;
}
