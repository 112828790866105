@import '@sass/app.scss';

.content {
  padding: 10px;
}

.row {
  display: flex;
}

.row p:first-child {
  flex: 1;
}

.row p:last-child {
  flex: 2;
}

.modificationType {
  display: flex;
  .bean {
    margin: auto;
  }
}

.modifications {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.item {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 130px;
  height: 150px;
  display: flex;
  margin: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  img {
    width: 70px;
    margin: auto;
  }
  p {
    margin: auto;
  }
}
