@import '@sass/app.scss';

.animated-input__container {
  .animated-input__right::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid $primary;
    transform: translateX(-200%);
    transition: transform 0.3s ease;
  }
  .animated-input__left::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -1px;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid $primary;
    transform: translateX(200%);
    transition: transform 0.3s ease;
  }

  input {
    width: 100%;
    height: 100%;
    color: rgba(0, 0, 0, 0.3);
    border: none;
    outline: none;
    font-size: 12px;
    margin-bottom: 10px;
  }
  label {
    position: absolute;
    bottom: -10px;
    left: 0px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }

  span {
    position: absolute;
    bottom: 5px;
    left: 0px;
    transition: all 0.3s ease;
  }

  input:focus + label span,
  input:valid + label span {
    transform: translateY(-120%);
    font-size: 11px;
    color: $primary;
  }
  input:focus + label::after,
  input:valid + label::after {
    transform: translateX(0%);
  }
}
