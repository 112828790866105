@import '@sass/app.scss';
.button {
  position: relative;
  div {
    margin: auto;
    border-radius: 50%;
    background-color: #ebebeb;
    height: 40px;
    width: 40px;
    display: flex;
    position: relative;

    svg {
      margin: auto;
      color: #3383ff;
    }

    @media screen and (max-width: $mobile-xmedium) {
      width: 30px;
      height: 30px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.background {
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: var(--popup-background-color);
}

.alert {
  position: absolute;
  background-color: $warning;
  border-radius: 50%;
  font-size: 13px;
  font-family: 'Poppins Regular';
  color: #fff;
  padding: 1px 6px;
  height: 20px;
  width: 20px;
  top: -5px;
  right: -5px;
  @media screen and (max-width: $mobile-xmedium) {
    padding: 0px 6px;
    font-size: 10px;
    height: 15px;
    width: 15px;
  }
}
