@import '@sass/app.scss';

.postsList {
  background-color: white;
  padding-top: 150px;
  padding-bottom: 5em;
  @media screen and (max-width: $desktop-xsmall) {
    padding-top: 0px;
  }
}

.container {
  height: 750px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: var(--background-color-light);
}
