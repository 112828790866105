@import '@sass/app.scss';

.container {
  background-color: white;
  flex-direction: column;
  padding: 10px;
  border-radius: $card-radius;
  width: 100%;
  margin: auto;
}

.content {
  overflow-y: scroll;
  max-height: 90vh;
  max-height: 80vh;
  padding-top: 10px;
  .ant-timeline {
    margin-top: 7px;
  }
}

.content::-webkit-scrollbar {
  display: none;
}

.item {
  cursor: pointer;
}
