@import '@sass/app.scss';

.content {
  padding: 20px;
}

.errors {
  display: flex;
  flex-direction: column;
}
