@import '@sass/app.scss';

.section6__container {
  height: 650px;
  background-color: $light;
}
.section6__content {
  max-width: var(--max-width-layout);
  width: 95%;
  margin: 0 auto 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  gap: 50px;
  height: 100%;
}
.section6__left {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
}
.section6__right {
  flex: 0 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.section6__buttons {
  display: flex;
  flex-direction: column;
  width: 400px;
  justify-content: center;
}
.section6__image {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: $desktop-large) {
  .section6__container {
    height: 70em;
    background-color: $light;
    padding-top: 120px;
  }
  .section6__content {
    flex-direction: column;
    gap: 6rem;
  }
}
@media screen and (max-width: $mobile-large) {
  .section6__left {
    width: 80%;
  }
  .section6__container {
    height: 60em;
  }
}
