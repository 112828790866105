@import '@sass/app.scss';

.container {
  display: flex;
  margin: auto;
}

.login {
  font-family: $main-font-light;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  color: $secondary-back;
  background-color: $main;
  border: none;
  border-radius: 1rem;
  transition: filter 300ms;
  &:hover {
    filter: brightness(1.2);
  }

  @media screen and (max-width: $mobile-large) {
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: $mobile-medium) {
    font-size: 12px;
    font-weight: 600;
    padding: 0.175rem 0.5rem;
  }
}

.register {
  font-family: $main-font-light;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: block;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  color: $main;
  background-color: transparent;
  border-radius: 1rem;
  transition: filter 300ms;
  &:hover {
    text-decoration: underline !important;
    filter: brightness(1.2);
  }
  @media screen and (max-width: $mobile-large) {
    // display: none;
    font-size: 14px;
    font-weight: 600;
  }
  @media screen and (max-width: $mobile-medium) {
    font-size: 12px;
    font-weight: 600;
    padding: 0.375rem 0.3rem;
  }
}
