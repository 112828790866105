@import '@sass/app.scss';

$shadow: 0px 2px 8px -4px rgba(9, 30, 66, 1);

.item {
  font-size: 13px;
  padding: 15px;
  border-radius: 5px;
  z-index: 1;
  background-color: var(--background-color-light);
  max-width: 200px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: $shadow;
}
.item-edit-button {
  z-index: 2;
}
.item p {
  font-size: 15px;
}

.kanban-add-card-button {
  margin-bottom: 15px;
  background-color: var(--background-color-light);
  border: none;
  width: 100%;
  height: 35px;
  color: $details-text;
  border-radius: 5px;
  box-shadow: $shadow;

  &:hover {
    color: #3383ff;
  }
}
.plus-icon {
  margin: 5px 0px 5px 0px;
}

.item:hover {
  cursor: pointer;
}

.item-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto;
  width: 100px;
}

.item-icons > * {
  margin-right: 10px;
}

.drop-wrapper {
  flex: 1 25%;
  width: 100%;
  height: 100%;
}

.kanban-title {
  margin-bottom: 30px !important;
}

.kanban-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  border-radius: 0.8rem;
}
.col-wrapper {
  background-color: var(--background-color-light);
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 15px 5px;
  border-radius: 5px;
  width: 100%;
}

.col-group {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  margin-bottom: 20px;
  color: #778399;
  font-size: 0.83em;
}

.col-header {
  letter-spacing: 2.5px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: normal;
  margin: inherit 0px unset 0px;
  font-family: $main-font;
  color: black;
}

.kanban-ctn {
  display: flex;
  flex: 1;
}

.col-count {
  display: none;
}

.highlight-region {
  background-color: yellow;
}

.kanban-priority-very-high {
  background-color: #d50000;
  border-radius: 0.8rem;
  color: var(--background-light) !important;
}

.kanban-priority-high {
  background-color: #ff7451;
  border-radius: 0.8rem;
  color: var(--background-light) !important;
}

.kanban-priority-medium {
  background-color: #f9a825;
  border-radius: 0.8rem;
  color: var(--background-light) !important;
}

.kanban-priority-low {
  background-color: #2683ff;
  border-radius: 0.8rem;
  color: var(--background-light) !important;
}

.item-priority-fullfilled {
  text-align: center;
}
.item-wrapper-content {
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 150px;
  .clamp-lines {
    p {
      font-size: 0.8rem;
      margin: 0;
    }
    .clamp-lines__button {
      display: none;
    }
  }
}
.item-wrapper__estimate {
  background-color: var(--global-color-main-traslusent);
  border: 1px solid var(--global-color-main);
  border-radius: 2rem;
  width: 60px;
  display: flex;
  margin: 5px auto;
  font-size: 8px !important;
}
.window-title-ctn {
  display: flex;
}
.board-item-input {
  background-color: transparent;
  color: $main;
  flex: 10;
  border: 1px solid $window !important;
  &:hover {
    border: 1px solid lightgray !important;
  }
}
.board-item-description {
  margin-bottom: 20px;
}
.board-item-title {
  font-family: $main;
  color: black;
  border: 1px solid $window;
}
.window-line {
  display: flex;
  margin-bottom: 8px;
}
.window-line p {
  font-family: $main-font-light;
  margin-left: 10px;
  font-size: 15px;
}
.input-item-title {
  font-size: 25px;
  font-family: $main-font;
  margin-bottom: 25px;
}
.item-wrapper-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.item-wrapper-title {
  flex: 10;
}

.window-line-description {
  flex: 0 0 120px;
  color: var(--details-text-color);
}
.window-container {
  font-family: $main-font-light;
  color: $main;
  font-weight: normal;
  position: relative;
  height: 340px;
}
.input-item-common {
  color: $main !important;
  font-weight: normal;
}
.window-line-select-wrapper {
  position: relative;
  font-family: $main-font-light;
}
.window-line-select {
  background-color: transparent;
  border: none;
  outline: 0;
  border-radius: 0.8rem;
  padding: 3px;
  border: 1px solid $window;
}
.window-line-select:hover {
  border: 1px solid #cbd4db;
}
.item-delete-container {
  display: flex;
  button {
    flex: 1;
  }
}
.item-delete-button {
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 0.8rem;
  margin: 5px;
}
.delete-icon {
  margin-top: 3px;
  margin-right: 5px;
}
.item-delete-button:hover {
  border: 1px solid $main;
}

@media screen and (max-width: $mobile-small) {
  .kanban-container {
    flex-direction: column;
  }
  .col-wrapper {
    flex-direction: row;
  }
  .drop-wrapper .col {
    display: flex;
    flex-direction: row;
  }
  .col-wrapper {
    max-width: none;
    display: inline-block;
    white-space: nowrap;
    max-width: 350px;
    overflow-x: scroll;
  }
  .item {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-right: 10px;
    justify-content: space-between;
  }
  .kanban-add-card-button {
    min-width: 200px;
    max-width: 200px;
  }
}
