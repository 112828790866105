@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
  margin-bottom: 25px;
  animation: moveInRight 0.5s ease-out 0.1s;
  animation-fill-mode: backwards;
  padding: 20px;
  display: flex;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: $mobile-large) {
    flex-direction: column;
  }
}

.left {
  flex: 1;
}

.right {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.bottom {
  margin-top: auto;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
}

.skills {
  margin-bottom: 10px;
}

.description {
  overflow-y: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  max-height: 95px;
  transition: max-height 0.4s ease-out;
  &:hover {
    max-height: 200px;
  }
}

.timeStamp {
  display: flex;
  p {
    margin-left: auto;
    margin-right: 0px;
  }
}
