@import '@sass/app.scss';

.container {
  @media screen and (max-width: $desktop-large) {
    margin-left: 0px;
  }
}

.card {
  padding: 5px;
  border-radius: $card-radius;
  background-color: white;
}

.content {
  padding: 5px;
}

.content::-webkit-scrollbar {
  height: 90%;
}

.no-questions {
  display: flex;
  img {
    width: 70%;
    margin: auto;
  }
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 10px;
}

.question {
  display: flex;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  h3,
  p {
    margin-left: 10px;
  }
}

.answer {
  display: flex;
  width: 100%;
}

.adviceContainer {
  border-radius: $card-radius;
  background-color: white;
  padding: 10px;
}
.questionTitle {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
