@import '@sass/app.scss';

.orderBy-button {
  font-family: $main-font;
  min-width: 223px;
}

.orderBy-select {
  font-family: $main-font;
  border: none;
  background-color: $secondary-back;
  margin-left: 10px;
  border-radius: $button-radius;
  padding: 5px;
  outline: 0;
}

/* Centra los textos en las opciones de select en Mozilla y Chrome */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-overflow: '';
  text-align-last: center;
}
