@import '@sass/app.scss';
.payment-order-more__row {
  display: flex;
  p:first-child {
    flex: 2;
    max-width: 130px;
    margin: 5px 0px;
  }
  p:last-child {
    flex: 3;
    margin: 5px 5px;
  }

  .status--created {
    background-color: $main;
    color: white;
    border-radius: $button-radius;
    max-width: 80px;
    text-align: center;
  }
  .status--accomplished {
    background-color: $secondary;
    color: white;
    border-radius: $button-radius;
    max-width: 80px;
    text-align: center;
  }
}

.payment-order-more__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.payment-order-more__blue-background {
  background-color: $main;
  height: 60px;
}

.payment-order-more__content {
  padding: 10px 24px;
  flex: 1;
  overflow: auto;
  height: auto;
}
