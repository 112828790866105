@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
  margin-bottom: 20px;
}

.header {
  background-color: var(--popup-background-color);
  padding: 10px 20px;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
}
.selected {
  background-color: $main;
}

.content {
  padding: 20px;
  .ant-input-textarea {
    margin-bottom: 20px;
  }
}

.footer {
  display: flex;
  padding: 20px 0px;
  button {
    margin: auto;
  }
}
