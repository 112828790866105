@import '@sass/app.scss';

.publication-questions-container {
  display: flex;
  flex-direction: column;
  border-radius: $card-radius;
  background-color: white;
  padding: 15px;
}
.publication-questions__no-questions {
  display: flex;
  img {
    width: 250px;
    margin: 20px auto;
  }
}
.publication-create-question {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  button {
    margin-right: 0px;
    margin-left: auto;
  }
}
.publication-create-question-input {
  flex: 4;
  background-color: $secondary-back;
  border: none;
  border-radius: 0.8rem;
}
