@import '@sass/app.scss';

.verify-identity__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    margin: 0px auto 20px auto;
  }
  .ant-form .ant-row {
    margin-bottom: 10px;
  }
}
.verify-identity__container h2 {
  font-family: $main-font;
  margin-bottom: 25px;
  color: $main;
  text-align: center;
}
.verify-identity__container p {
  padding: 10px 30px;
  text-align: justify;
  text-justify: inter-word;
}
.verify-identity__container {
  font-family: $main-font;
}

.verify-identity__form {
  padding: 0px 30px;
}

.verify-identity__form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  p {
    padding: 0px;
    margin-bottom: 5px;
  }
}
.verify-identity__button {
  display: flex;
  margin-bottom: 20px;
  button {
    margin: auto;
  }
}
