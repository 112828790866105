@import '@sass/app.scss';

.greenHeader {
  background-color: $secondary;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  display: flex;
}

.edit {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 2rem;
}

.container {
  padding: 20px;
}
