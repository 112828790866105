@import '@sass/app.scss';

.orders-table__container {
  background-color: white;
  border-radius: $card-radius;
  padding: 20px;
  hr {
    border-top: 2px solid rgba(0, 0, 0, 0.3);
  }
  @media screen and (max-width: $mobile-xlarge) {
    width: 760px;
  }
}

.orders-table__content {
  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.orders-table__header {
  display: flex;
  .tn-title {
    flex: 1;
    margin: auto;
    text-align: center;
  }
  #proyect {
    flex: 3;
  }
  #stage {
    flex: 2;
  }
}
.orders-table__row {
  display: flex;
  .tn-text {
    flex: 1;
    margin: auto;
    text-align: center;
  }
  #proyect {
    flex: 3;
  }
  #stage {
    flex: 2;
  }
  .status--created {
    background-color: $main;
    color: white;
    border-radius: $button-radius;
  }
  .status--accomplished {
    background-color: $secondary;
    color: white;
    border-radius: $button-radius;
  }
}
