.tn-row {
  display: flex;
  min-height: 20px;
  margin-bottom: 10px;
  width: 100%;
  &:last-child {
    margin-bottom: 0px;
  }
  justify-content: space-between;
}
