@import '@sass/app.scss';

.view-request-drawer__request-breakdown {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0px;
  padding: 15px;

  hr {
    height: 2px;
    width: 80%;
  }
}

.view-request-drawer__accept-button {
  display: flex;
  margin-top: 20px;
  button {
    margin: auto;
  }
}

.view-request-drawer__intellectual-prop {
  display: flex;
}

.view-request-drawer__blue-background {
  background-color: $main;
  height: 60px;
}

.view-request-drawer__content {
  padding: 10px 24px;
}
