@import '@sass/app.scss';

.message-item__container {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background 500ms;
  padding: 0.5rem;
  border-radius: 8px;
  p {
    flex: 5;
  }
}

.message-item__container:hover {
  background-color: var(--menu-hover);
}

.message-item__content {
  display: flex;
  padding: 5px;
  border-radius: 0.8rem;
  width: 100%;
  h1 {
    font-size: 30px;
    margin: auto;
  }
}

.message-item__image {
  flex: 1;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    @media screen and (max-width: $mobile-xmedium) {
      width: 40px;
      height: 40px;
    }
  }
}

.message-item__template {
  flex: 10;
  margin: auto 10px;
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: auto;
  }
}
