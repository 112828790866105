.section {
  display: flex;
  margin-bottom: 10px;
  p {
    width: 40px;
  }
  input {
    width: 100%;
    border-radius: 2rem;
    border: 1px solid lightgray;
  }
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
