@import '@sass/app.scss';
.section2__container {
  height: 460px;
  background-color: var(--background-color-light);
  padding-top: 50px;
}
.section2__content {
  max-width: var(--max-width-layout);
  width: 100%;
  height: 350px;
  margin: 0 auto;
  overflow: visible;
}
.advantages__card {
  height: 350px;
  width: 260px;
  border: 1px solid $main;
  border-radius: 3rem;
  margin: auto;
}
.advantages__top {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding-top: 40px;
}
.advantages__bottom {
  height: 60%;
  text-align: center;
  padding: 20px 20px 10px 20px;
}
.icon__color {
  color: $main;
}
@media screen and (max-width: 1450px) {
}
.react-multiple-carousel__arrow {
  background-color: transparent;
  &:hover {
    transform: none;
    background-color: var(--background-color-light);
  }
}
.react-multi-carousel-item {
}
.react-multiple-carousel__arrow--left {
  left: 0px;
  z-index: 0;
}
.react-multiple-carousel__arrow--right {
  right: 0px;
  z-index: 0;
}
.react-multiple-carousel__arrow::before {
  color: #333333;
}
.advantages__icon {
  display: flex;
  align-items: center;
  color: #666666;
}
.advantages__icon svg {
  height: 30px;
}
