@import '@sass/app.scss';

.completed-projects-card__container {
  background-color: white;
  border-radius: 0.8rem;
  padding: 20px;
  margin: 10px 10px 0px 0px;
  display: flex;
  width: 100%;
  @media screen and (max-width: $mobile-xmedium) {
    margin-right: 0px;
  }
}
