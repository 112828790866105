@import '@sass/app.scss';

.project-layout {
  background-color: white;
  border-radius: $card-radius;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1em;
  @media screen and (max-width: $mobile-xlarge) {
    flex-direction: column;
  }
  @media screen and (max-width: $desktop-small) {
    padding-bottom: 20px;
  }
}

.project-layout:hover {
  cursor: pointer;
}

.project-layout__left {
  flex: 1;
  padding: 10px 0px 0px 20px;
}

.project-layout__right {
  flex: 2;
  padding: 10px 20px 0px 0px;
  display: flex;
  max-width: 395px;
  word-break: break-all;

  @media screen and (max-width: $mobile-xlarge) {
    padding: 20px;
    flex-direction: column;
  }
  hr {
    margin: 40px 10px 0px 0px;
    border: 0;
    height: 60%;
    border-left: 2px solid rgba(0, 0, 0, 0.1);

    @media screen and (max-width: $mobile-xlarge) {
      height: 0px;
      width: 90%;
      margin: 10px auto;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.project-layout__buttons {
  flex: 1;
  display: flex;
  margin-top: 10px;
}

.project-layout__bottom {
  width: 100%;
  margin-top: 30px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
