.icons__advantages {
  flex: 1 1 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
}
.icons svg {
  width: 100px;
  height: 100px;
}
.icons__subtitle {
  margin-top: 20px;
  width: 350px;
}
