@import '@sass/app.scss';

.client-order-more__row {
  display: flex;
  p:first-child {
    flex: 2;
  }
  p:last-child {
    flex: 3;
  }
}

.client-order-more__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.client-order-more__blue-background {
  background-color: $main;
  height: 60px;
}

.client-order-more__content {
  padding: 10px 24px;
  flex: 1;
  overflow: auto;
  height: auto;
}
