.registration-confirmation-input {
  line-height: 30px;
  font-weight: bolder;
  margin-top: 10px;
  width: 80% !important;
  margin-left: 10%;
}
.change-password-confirmation-button {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
}
.ant-input-password {
  border-radius: 0.8rem !important;
}
.ant-input {
  border-radius: 0.8rem;
}
.forgot-password-input {
  margin-bottom: 30px;
}
