@import '@sass/app.scss';

.about-me__container {
  background-color: white;
  border-radius: 0.8rem;
  flex: 30%;
  padding: 20px;
}
.about-me__section:not(:first-child) {
  margin-top: 60px;
}

.about-me__header {
  display: flex;
  h3 {
    margin: auto 5px;
  }

  .tn-btn {
    max-width: 100px;
    margin-left: auto;
    margin-right: 0px;
  }
}
.about-me__content {
  margin: 15px 5px;
  .clamp-lines .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
  }
}
