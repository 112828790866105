@import '@sass/app.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.timeline0 {
  width: 230px;
  height: 5px;
  align-self: center;
  background-color: #e5e5e5;
}
.timeline1 {
  height: 200px;
  width: 5px;
  background-color: #e5e5e5;
  align-self: center;
}
.timeline2 {
  height: 400px;
  width: 5px;
  background-color: #e5e5e5;
  align-self: center;
}
.timeline3 {
  height: 400px;
  width: 5px;
  background-color: #e5e5e5;
  align-self: center;
}
.timeline4 {
  height: 450px;
  width: 5px;
  background-color: #e5e5e5;
  align-self: center;
}
.timeline5 {
  height: 360px;
  width: 5px;
  background-color: #e5e5e5;
  align-self: center;
}
.circle {
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #e5e5e5;
}
.circleWrapper {
  position: relative;
  align-self: center;
  font-weight: bold;
  font-size: 1.5rem;
}
