@import '@sass/app.scss';

.team-member-card {
  text-align: center;
  margin: 10px;
  padding: 10px;
  flex: 1 0 30%;
  flex-direction: column;
  height: fit-content;
  background-color: white;
  max-width: 350px;
}
.team-member-card__image {
  width: 250px;
}
.team-member-card__feed {
  margin-top: 20px;
  text-align: center;
}
.team-member__hover {
  margin: auto;
  width: 250px;
  position: relative;
  text-align: center;
}
.team-member__background {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  background-color: $main;
  color: $secondary-back;
  margin-bottom: 0;
  border-radius: 50%;
  font-size: 11px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 25px 20px 20px 20px;
  word-wrap: break-word;
}
.team-member__hover:hover .team-member__background {
  visibility: visible;
  opacity: 0.9;
}
