// For example keyframes
@keyframes bounce {
  to {
    width: 20px;
    height: 20px;
    transform: translateY(-20px);
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
@keyframes grow {
  50% {
    transform: scale(1);
  }
}
