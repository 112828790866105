.ant-notification-notice {
  border-radius: 0.8rem;
  display: flex;
  .ant-notification-notice-content {
    width: 100%;
    display: flex;
    .ant-notification-notice-with-icon {
      width: 100%;
    }
  }
}

.ant-notification-notice-icon {
  margin: auto;
  img {
    width: 45px;
  }
}

.ant-notification-notice-message {
  margin-top: 20px;
  padding-left: 30px;
}
.ant-notification-notice-description {
  padding: auto;
  padding-left: 30px;
  min-height: 60px;
}

.ant-notification-close-x span svg {
  color: black;
}
