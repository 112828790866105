@import '@sass/app.scss';
.section8__section {
  background-color: $light;
  padding: 3em 0;
}

.section8__container {
  height: 100%;
  max-width: var(--max-width-layout);
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}
.left__container {
  border-radius: 2rem;
  border: 1px solid $secondary;
  min-width: 450px;
  padding: 20px;
  text-align: left;
}
.right__container {
  border-radius: 2rem;
  border: 1px solid $secondary;
  min-width: 450px;
  padding: 80px 30px 50px 30px;
  text-align: right;
}
.left__bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.right__bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.leftbottom__button {
  margin-left: 50px;
  text-align: center;
}
.rightbottom__button {
  margin: 140px 50px 0px 0px;
  text-align: center;
}
.button__title {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.button__whatsapp {
  background-color: $primary;
  color: var(--background-color-light);
  font-size: 16px;
  padding: 10px 15px;
  font-family: $main-font;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.whatsapp__section {
  margin-top: 10px;
}
.left__image {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: $desktop-small) {
  .section8__container {
    flex-direction: column;
  }
  .left__container {
    width: 80%;
    padding: 20px 10px 20px 10px;
    min-width: 300px;
    max-width: 500px;
  }
  .right__container {
    width: 80%;
    padding: 20px 10px 20px 10px;
    min-width: 300px;
    max-width: 500px;
  }
  .left__bottom {
    display: flex;
    flex-direction: column;
  }
  .right__bottom {
    flex-direction: column;
  }
  .leftbottom__button {
    margin-left: 0;
    text-align: center;
  }
  .rightbottom__button {
    margin: 20px 0 50px 0;
    text-align: center;
  }
}
