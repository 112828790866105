.social-media-input__container {
  padding: 10px;
}
.social-media-input__icon {
  width: 100%;
  font-size: 60px;
  display: flex;
  i {
    margin: auto;
  }
}
.social-media-input__title {
  display: flex;
  margin-bottom: 10px;
  h3 {
    margin: auto;
  }
}
.social-media-input__text {
  margin-bottom: 10px;
  display: flex;
  p {
    margin: auto;
  }
}
.social-media-input__form {
  display: flex;
  width: 100%;
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    .tn-btn {
      margin: auto;
    }
  }
}
