@import '@sass/app.scss';

.content {
  padding: 20px;
}

.functionalities {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.item {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 130px;
  height: 130px;
  display: flex;
  margin: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  img {
    width: 70px;
    margin: auto;
  }
  p {
    margin: auto;
  }
}

.nActiveUsers {
  display: flex;
}

.integrations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.integration {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 130px;
  height: 100px;
  display: flex;
  margin: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  img {
    max-width: 100px;
    max-height: 70px;
    margin: auto;
  }
  p {
    margin: auto;
  }
}
