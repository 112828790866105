@import '@sass/app.scss';

.publication_card__blue_header {
  background-color: white;
  height: 10px;
  width: 100%;
  border-top-right-radius: $card-radius;
  display: flex;
}

.publication_card__edit {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 2rem;
}
