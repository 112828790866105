/*COMPONENT NAME*/
.notificationIconCtn {
  font-size: 22px;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
