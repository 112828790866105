@import '@sass/app.scss';

.login__container {
  height: 600px;
  width: 100%;
  display: inline-block;
}
.card-login__container {
  align-content: center;
  display: block;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 10px;
}

.card-login__header {
  padding: 20px 0px 0px 40px;
}

.icon-login {
  height: 100px;
  width: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.button-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button-login-form {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  border: 1px solid transparent;
  padding: 0.55rem 0.75rem;
  font-family: $main-font;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  background-color: $main;
  color: white;
  border-radius: 0.8rem;
  transition: filter 300ms;
  &:hover {
    filter: brightness(1.2);
  }
}

.form-group-login {
  margin-bottom: 5px;
  margin-left: 10%;
  margin-right: 10%;
}

.form-control-login {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 10px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-icon-text {
  position: absolute;
  padding-top: auto;
  padding-bottom: auto;
  padding-left: 20px;
  color: #b2b0b0;
  display: flex;
}

.login-icon-text i {
  margin-right: 18px;
  margin-top: 10px;
  font-size: 25px;
}

.text-login {
  padding-left: 10px;
  padding-bottom: 20px;
}
.google-register-container {
  display: flex;
  margin: 20px auto;
  justify-content: center;
}

.google-button {
  font-family: $main-font-light;
  font-weight: bold;
  color: #969696;
  background-color: $secondary-back !important;
  border: 2px solid lightgray;
  border-radius: 2rem;
  font-size: 14px;
  padding: 5px 60px 5px 100px;
  background: url(../../../../../../assets/images/google-icon-38.png) left 20px center no-repeat;
  background-size: 25px;
  transition: 250ms;
  &:hover {
    background-color: lightgray !important;
    color: #495057;
    border-color: #495057;
  }
  @media screen and (max-width: $desktop-small) {
    padding: 5px 10px 5px 50px;
  }
}

.register-login-option-container {
  display: flex;
  justify-content: center;
  margin: 10px auto;
  .option-text {
    margin: 7px;
  }
}

.register-login-option-border-left {
  width: 160px;
  border: 1px solid lightgray;
  margin-left: 0;
  @media screen and (max-width: $desktop-small) {
    width: 80px;
  }
}

.register-login-option-border-right {
  width: 160px;
  border: 1px solid lightgray;
  margin-right: 0;
  @media screen and (max-width: $desktop-small) {
    width: 80px;
  }
}

.warning-container {
  text-align: center;
  font-family: $main-font-light;
  font-size: 16px;
  font-weight: bold;
  color: #495057;
  margin: 0px 10% 2rem 10%;
}

.label-form-text {
  font-family: $main-font;
  color: $main;
  font-weight: bold;
}

.ant-form-item-label {
  text-align: left;
}

.ant-checkbox + span {
  padding: 0px 8px;
  font-family: $main-font-light;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #495057;
}

.inside-input-text {
  font-family: $main-font-light;
  color: #969696;
  font-style: italic;
  letter-spacing: 2px;
  padding-top: 10px;
}

.option-text {
  font-family: $main-font;
  font-size: 12px;
  color: #969696;
}

.remember-forgot-container {
  margin: 5px 0px 10px 0px;
}

.login-form-forgot {
  padding: 0px 8px;
  font-family: $main-font-light;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.not-account {
  font-size: 10px;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}
.form-group-login {
  .ant-input {
    border-radius: 0.8rem;
  }
}

@media screen and (max-width: $mobile-large) {
  .card-login-container {
    margin-top: 10px;
  }
}
