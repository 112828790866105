@import '@sass/app.scss';

.question-box-container {
  border-radius: 0.8rem;
  background-color: $secondary-back;
  position: relative;
  margin-bottom: 20px;
}
.question-box-icon-question {
  display: flex;
  align-items: center;
  p {
    margin: auto 10px;
  }
}
.question-box-profile-image {
  width: 2rem;
  border-radius: 50%;
  margin-bottom: auto;
}
.question-box-icon-text {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  p {
    margin: auto 10px;
  }
}

.question-box-question-text {
  margin: 0 0 10px 60px;
  text-align: justify;
}

.send-reply-button {
  display: flex;
  button {
    margin: auto;
  }
}
.question-box-content-answer {
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 50px;
}
.question-box-question-text-answer {
  margin-left: 60px;
  text-align: justify;
}
.question-box-reply-container {
  display: flex;
  flex-direction: column;
}
.question-box-delete-button {
  position: absolute;
  right: 5px;
  top: 0;
  border: none;
  color: gray;
}
.question-reply-button {
  background-color: rgba(247, 243, 243, 0.966);
  border-radius: 0.8rem;
  outline: 0;
  margin-left: 5px;
}
.question-reply-button:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
}
