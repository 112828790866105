@import '@sass/app.scss';

.tn-drawer-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0px 24px;
  button {
    margin: 10px auto;
  }
}

.menu__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.menu__blue-background {
  background-color: $main;
  height: 60px;
}

.menu__header {
  padding: 5px 24px;
}

.menu__content {
  flex: 1;
  overflow: auto;
  height: auto;
}

.menu-item-dropdown {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background 500ms;
  padding: 0.5rem 0;
  border-radius: 8px;
}

.menu-item-dropdown:hover {
  background-color: var(--menu-hover);
}

.menu-item-drawer {
  svg {
    height: 15px;
    width: 15px;
    color: var(--details-text-color);
  }
}

.menu-item-selected {
  svg {
    color: var(--global-color-main);
  }
  .menu-item-text {
    p {
      color: var(--global-color-main);
    }
  }
}

.menu-item-log-out {
  svg {
    color: #e60026;
  }
}
