@import '@sass/app.scss';

.chat__sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 100%;
  height: 100%;
  background: var(--background-color-light);
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
  padding: 20px;
  min-height: 150px;
  border-radius: 0.8rem;
}
.tn-chat-contacts {
  margin-top: 0px;
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tn-chat-contacts.expanded {
  height: calc(100% - 334px);
}
.tn-chat-contacts::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.tn-chat-contacts::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.tn-chat-contacts ul li.contact {
  cursor: pointer;
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid lightgray;
  margin-bottom: 10px;
  list-style-type: none;
  .user-card-body {
    width: 100%;
    .user-card-data {
      h5 {
        font-size: 16px;
      }
      h6 {
        font-size: 15px;
      }
    }
  }
}

.tn-chat-contacts ul li.contact:hover {
  border: 2px solid $primary;
}
.tn-chat-contacts ul li.contact.selected {
  border: 2px solid $primary;
}
.preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
.preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}
.contacts-list {
  margin: 0;
  padding: 10px 10px 10px 0;
}

@media screen and (max-width: $mobile-large) {
  .chat__sidepanel {
    width: 100px;
    min-width: 100px;
  }

  .chat__sidepanel .tn-chat-contacts {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .chat__sidepanel .tn-chat-contacts::-webkit-scrollbar {
    display: none;
  }

  .chat__sidepanel .tn-chat-contacts ul li.contact {
    padding: 6px 0 66px 8px;
  }

  .chat__sidepanel .bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }

  .chat__sidepanel .bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }

  .chat__sidepanel .bottom-bar button i {
    font-size: 1.3em;
  }

  .chat__sidepanel .bottom-bar button span {
    display: none;
  }
}
