.tabs_items_container {
  display: flex;
  cursor: pointer;
  p:first-child {
    border-top-left-radius: 1em;
  }
  p:last-child {
    border-top-right-radius: 1em;
  }
}
.tab_item {
  background-color: #fafafabb;
  padding: 0.3em 1em;
}
.active {
  background-color: #ffffff;
  color: #3383ff;
}
