@import '@sass/app.scss';

.chat__messages {
  overflow-y: scroll;
  height: 40vh;
  width: 100%;
  background-color: #fff;
  border: 1px solid lightgray;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  ul {
    margin: 5px;
    padding-left: 0px;
    li {
      display: inline-block;
      clear: both;
      height: 100%;
      margin: 10px;
      width: 100%;
      margin: 0px;
    }
  }
}

.chat-messages__content {
  display: flex;
  max-width: 500px;
}

.chat__messages ul .chat__messages ul li:nth-last-child(1) {
  margin-bottom: 10px;
}
.chat__messages ul li.sent {
  .chat-messages__content {
    float: left;
  }
}
.chat__messages ul li.sent p {
  background-color: rgba(0, 213, 161, 0.05);
  color: black;
  border: 1px solid rgb(0, 213, 161);
  border-bottom-left-radius: 0rem;
}
.chat__messages ul li.replies {
  .chat-messages__content {
    float: right;
    flex-direction: row-reverse;
  }
}
.chat__messages ul li.replies p {
  background-color: var(--global-color-main-traslusent);
  border: 1px solid var(--global-color-main);
  float: right;
  border-bottom-right-radius: 0rem;
}
.chat-messages__image {
  margin: auto 5px 0px 5px;
  img {
    width: 40px;
    border-radius: 50%;
  }
}
.chat__messages ul li {
  p {
    display: inline-block;
    padding: 12px;
    border-radius: 0.8rem;
    line-height: 110%;
    margin-bottom: 0px;
  }
}
