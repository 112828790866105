@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
}

.content {
  background-color: white;
  border-radius: 0.8rem;
  flex-direction: column;
  padding: 20px;
  max-height: 95vh;
  overflow-y: scroll;
}

.inviteContent {
  background-color: white;
  padding: 20px;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.content::-webkit-scrollbar {
  display: none;
}

.detailProject {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 1em;
  &:hover {
    cursor: pointer;
  }
}

.detailFreelancer {
  border: 1px solid lightgrey;
  border-radius: $card-radius;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 10px;

  &:hover {
    cursor: pointer;
    border-color: $secondary;
  }
}
.detailInvite {
  border: 1px solid lightgrey;
  border-radius: $card-radius;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 1em;
  max-width: 300px;
  min-width: 250px;
  max-height: 380px;
}

.headerMain {
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  height: 30px;
  padding: 10px 5px 5px 5px;
  text-align: center;
  width: 100%;
}

.headerSecondary {
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  height: 30px;
  padding: 10px 5px 5px 5px;
  text-align: center;
  width: 100%;
}

.image {
  border-radius: 50%;
  max-width: 80px;
  max-height: 80px;
  margin: auto;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.body {
  margin: 2px 0 2px;
  width: 100%;
}
.top {
  display: flex;
  flex-direction: column;
}

.bottom {
  margin: 5px 10px 0;
}

.description {
  text-align: justify;
  font-size: 13px;
}

.skills {
  @include skills-element;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
}

.inviteBtn {
  text-align: center;
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
}
