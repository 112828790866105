@import '@sass/app.scss';

.view-request-breakdown__stage {
  display: flex;
  flex-direction: row;
  p:first-child {
    flex: 2;
  }
  p:nth-child(2) {
    flex: 1;
  }
  p:nth-child(3) {
    flex: 1;
  }
}

.view-request-breakdown__stage p {
  margin: 2px;
}

.view-request-breakdown__header {
  display: flex;
  flex-direction: row;
  p:first-child {
    flex: 2;
  }
  p:nth-child(2) {
    flex: 1;
  }
  p:nth-child(3) {
    flex: 1;
  }
}

.view-request-breakdown__container {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  padding: 15px;
}
.view-request-breakdown__header {
  display: flex;
  h4 {
    margin: auto 0px;
  }
  .tn-btn {
    margin: auto 0px auto auto;
  }
}

.view-request-breakdown__row-show {
  display: flex;
  flex-direction: row;
  gap: 0.2em;
}

.view-request-breakdown__title-show {
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  svg {
    color: $main;
    margin: auto 5px;
    padding-bottom: 7px;
    width: 15px;
  }
  p {
    margin: 0px;
  }
}

.view-request-breakdown__price-show {
  position: relative;
  flex: 1;
}
