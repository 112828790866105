@import '@sass/app.scss';

.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.itemContainer {
  flex: 50%;
  max-width: 50%;
  min-width: 310px;
  padding: 4px;
}
.item {
  height: 70px;
  border: 1px solid lightgray;
  border-radius: 2rem;
  display: flex;
  svg {
    color: grey;
    width: 40px;
    margin: auto 10px auto 30px;
  }
  p {
    margin: auto 0px;
  }
}
.item:hover {
  border: 1px solid $main;
}

.itemSelected {
  height: 70px;
  border: 1px solid $main;
  border-radius: 2rem;
  display: flex;
  svg {
    width: 40px;
    color: $main;
    margin: auto 10px auto 30px;
  }
  p {
    color: $main;
    margin: auto 0px;
  }
}

.radioContainer {
  margin: auto 15px auto auto;
}
