.container {
  padding: 10px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
  padding: 10px 0px;
}

.item {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  height: 80px;
  max-width: 120px;
  display: flex;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  flex: 40%;
  img {
    max-width: 90px;
    max-height: 50px;
    margin: auto;
  }
}
.all_categories {
  border-radius: 1em;
  border: 1px solid lightgrey;
  padding: 10px;
  text-align: center;
}
