.container {
  display: flex;
  flex-direction: column;
}

.formContainer {
  margin-top: 20px;
}

.buttonContainer {
  display: flex;
}

.buttonContainer > div {
  margin: auto;
}
