@import '@sass/app.scss';

.profile-picture__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile-picture__image-container {
  display: inline-block;
  position: relative;

  padding: 0;
  margin: 0;
  margin: auto;
}

.profile-picture__image {
  margin: auto;
  display: flex;
  img {
    border-radius: 50%;
    margin: auto;
  }
}
.profile-picture__rate {
  margin: auto;
}

.profile-picture__edit-button {
  position: absolute;
  top: 100px;
  left: 140px;
  button {
    background-color: var(--background-color);
    border-radius: 50%;
    border: 0;
    font-weight: bold;
    font-size: 20px;
    margin: 2px;
    color: black;
  }
}

.profile-picture__edit-button:hover {
  color: $primary;
}
