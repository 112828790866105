@import '@sass/app.scss';

.whatsapp__button-container {
  width: 50px;
  height: 50px;
  background-color: #00bb2d;
  position: fixed;
  bottom: 30px;
  left: 100px;
  border-radius: 2rem;
  z-index: 99;
  &:hover {
    transform: scale(1.02);
  }
  @media screen and (max-width: $desktop-medium) {
    left: 30px;
    bottom: 20px;
  }
}
.whatsapp__button-content {
  font-size: 18px;
  font-family: $main-font-medium;
  color: #fff;
  @media screen and (min-width: $mobile-small) and (max-width: $mobile-large) {
    display: none;
  }
}
.whatsapp__left-content {
  float: left;
  margin-right: 10px;
}
.whatsapp__right-content {
  font-size: 30px;
  display: flex;
  align-items: center;
}
.whatsapp__content {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 10px 12px;
}
.whatsapp__icon {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
