@import '@sass/app.scss';

.container {
  display: flex;
}

.buttons {
  display: flex;
  margin: auto 16px;
}

.publishButton {
  display: flex;
  button {
    margin: auto 10px;
  }
  @media screen and (max-width: $mobile-xmedium) {
    display: none;
  }
}

.profileDropdown {
  display: flex;
  min-width: 200px;
  button {
    display: flex;
    margin: auto;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      @media screen and (max-width: $mobile-xmedium) {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media (max-width: $desktop-small) {
  .profileDropdown {
    min-width: 0;
  }
}
.text {
  display: flex;
  margin: auto 0px auto 10px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
