.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  img {
    margin: auto;
    width: 40px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  p {
    margin: 10px;
  }
  button {
    margin: auto;
  }
}

.paragraphs {
  overflow-y: scroll;
  max-height: 68vh;
}

.paragraphs::-webkit-scrollbar {
  display: none;
}
