@import '@sass/app.scss';

.tn-text {
  white-space: pre-wrap;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
  word-break: break-word;
  @media screen and (max-width: $mobile-xmedium) {
    font-size: 0.8rem;
  }

  &--primary {
    font-family: $main-font-medium;
    color: $primary-text;
  }
  &--detail {
    color: $details-text;
    font-family: $main-font-light;
  }
  &--regular {
    font-family: $main-font;
    color: $primary-text;
  }
  &--logOut {
    color: #e60026;
    font-family: $main-font-light;
    font-size: 15px;
    margin: 7px 0px 6px 0px;
    font-weight: bold;
  }

  //SIZE
  &--big {
    font-size: 1rem;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.9rem;
    }
  }
  &--bigDetail {
    font-size: 1rem;
    color: $details-text;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.9rem;
    }
  }
  &--bigStrong {
    font-size: 1rem;
    font-weight: bold;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.9rem;
    }
  }
  &--boldDetail {
    font-weight: bold;
    color: $details-text;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.9rem;
    }
  }
  &--blue {
    color: $main;
  }
  &--normalWhite {
    color: white;
  }
  &--normalBoldDetail {
    color: $details-text;
    font-family: $main-font-light;
    font-weight: bold;
  }
  &--small {
    font-size: 0.7rem;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallDetail {
    font-size: 0.7rem;
    color: $details-text;
    font-family: $main-font-light;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallBold {
    font-size: 0.7rem;
    color: $primary-text;
    font-weight: bold;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallBoldDetail {
    font-size: 0.7rem;
    color: $details-text;
    font-family: $main-font-light;
    font-weight: bold;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallBoldBlue {
    font-size: 0.7rem;
    color: $main;
    font-weight: bold;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallBlue {
    font-size: 0.7rem;
    color: $main;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--smallBlack {
    font-size: 0.7rem;
    color: $primary-text;
    font-weight: normal;
    @media screen and (max-width: $mobile-xmedium) {
      font-size: 0.6rem;
    }
  }
  &--strong {
    font-weight: bold;
  }
  &--strongBlue {
    font-weight: bold;
    color: $main;
  }
  &--spaced {
    letter-spacing: 0.5px;
  }
  &--centered {
    text-align: center;
  }
  &--justify {
    text-align: justify;
  }
  &--italic {
    font-family: $main-font-italic;
  }
  &--item {
    margin: auto;
  }
  &--legalFiles {
    color: $grey-font;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 15px;
    text-align: justify;
  }

  &.primaryColor {
    color: $primary;
  }
  &.secondaryColor {
    color: $secondary;
  }
  &.successColor {
    color: green;
  }
  &.whiteColor {
    color: white;
  }
}
