@import '@sass/app.scss';

.baseform-layout-container {
  display: flex;
  font-family: $main-font-light;
  position: relative;
}
.svg-screen-cover-left {
  flex: 1;
  height: 500px;
  background-image: url(../../../assets/images/confirmationemail.svg);
  background-size: cover;
  background-position: right;
  position: relative;
  border-radius: 0.8rem;
}
.baseform-layout-content {
  flex: 1;
}
.svg-screen-cover-right {
  flex: 1;
  height: 500px;
  background-image: url(../../../assets/images/confirmationemailright.svg);
  background-size: cover;
  background-position: left;
  position: relative;
  border-radius: 0.8rem;
}

@media (max-width: 500px) {
  .baseform-layout-container {
    flex-direction: column;
  }
  // .svg-screen-cover-left{

  // }

  // .basic-container .baseform-layout-content .baseform-body{

  // }

  // .svg-screen-cover-right{

  // }
}

@media only screen and (min-width: 501px) and (max-width: 900px) {
}
