@import '@sass/app.scss';

.request-card__profile {
  display: flex;
  flex-direction: column;
  flex: 3;
  .request-card__content {
    display: flex;
    flex-direction: column;
  }
}

.request-card__header {
  max-height: 50px;
  display: flex;
  p {
    margin: 10px;
  }
}

.request-card__user-stats {
  margin: 10px;
}
.request-card__project {
  flex: 4;
  .request-card__content {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 0px 10px;
    @media screen and (max-width: $mobile-xlarge) {
      padding-left: 20px;
      margin: 0px;
    }
  }
}

.request-card__categories {
  display: flex;
  flex-direction: column;
}

.request-card__buttons {
  margin: auto;
}

.request-card__subcategories {
  display: flex;
}
