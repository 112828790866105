.other-requests__scroll-content {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  min-height: 200px;
  height: 100%;
}

.other-requests__scroll-content::-webkit-scrollbar {
  display: none;
}
