@import '@sass/app.scss';

.bank-account-card__container {
  flex: 40%;
  border-radius: 0.8rem;
  border: 1px solid lightgray;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 5px;
  }
}

.bank-account-card__container:hover {
  box-shadow: $main-shadow-hover;
  cursor: pointer;
}

.bank-account-card__container--active {
  flex: 40%;
  border-radius: 0.8rem;
  border: 1px solid $primary;
  margin: 10px;
  padding: 20px;
  background: rgba(51, 131, 255, 0.02);
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 5px;
  }
}

.bank-account-card__container--active:hover {
  box-shadow: $main-shadow-hover;
  cursor: pointer;
}

.bank-account-card__clickeable {
  width: 90%;
}
