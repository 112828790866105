@import '@sass/app.scss';

.general-information__container {
  background-color: white;
  border-radius: 0.8rem;
  flex: 30%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $mobile-xlarge) {
    margin-right: 0px;
  }
}

.general-information__header {
  background-repeat: no-repeat;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  height: 50px;
  margin-bottom: 10px;
  background-color: $main;
}
.general-information__preview {
  margin-left: 10px;
  position: absolute;
}

.general-information__content {
  display: flex;
  padding: 20px;
  margin-top: 20px;
  @media screen and (max-width: $mobile-xmedium) {
    flex-direction: column;
    margin-top: 0px;
  }
}

.general-information__edit-picture {
  flex: 1;
  display: flex;
  flex-direction: column;
  .tn-btn {
    width: 80px;
    margin: 0px auto 20px auto;
  }
}

.general-information__verify-identity {
  img {
    width: 30px;
  }
}
.general-information__info-form {
  flex: 2;
  margin: -40px 20px 0px 20px;
  @media screen and (max-width: $mobile-xmedium) {
    margin-top: 0px;
  }

  .input-row {
    width: 100%;
  }
}

.general-information__button {
  display: flex;
  button {
    margin-left: auto;
    margin-right: 0px;
  }
  .tn-btn {
    max-width: 100px;
  }
}

.general-information__row {
  display: flex;
  align-items: center;
  margin: 12px;
  // p {
  //   flex: 1;
  //   margin: 0.5em;
  // }
  h3 {
    max-width: 120px;
    width: 100%;
  }
  .ant-form-item {
    margin: 0px !important;
  }
}

.general-information__edit-picture {
  display: flex;
}
.prefixSection {
  width: 30%;
}
.numberSection {
  width: 70%;
}
