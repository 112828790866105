@import '@sass/app.scss';

.skills-beans__container {
  display: flex;
  flex-wrap: wrap;
}

.skills-beans__element {
  @include skills-element;
}
