@import '@sass/app.scss';

.payment-drawer__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.payment-drawer__blue-background {
  background-color: $main;
  height: 60px;
}

.payment-drawer__header {
  padding: 5px 24px;
  hr {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
  }
}

.payment-drawer__content {
  flex: 1;
  overflow: auto;
  height: auto;
  padding: 0 24px;
}

.payment-drawer__categories {
  display: flex;
  flex-direction: row;
}

.payment-drawer__submit-button {
  align-content: center;
  margin: 20px auto;
  text-align: center;
}

.payment-drawer__options {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
.payment-drawer__request-breakdown {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0px;
  padding: 15px;

  hr {
    height: 2px;
    width: 80%;
  }
}

.payment-drawer__total-payment {
  display: flex;
  h3 {
    flex: 1;
  }
}

.payment-drawer__checkout {
  display: flex;
  flex-direction: column;
  button {
    margin: 20px auto;
  }
}
