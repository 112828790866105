@import '@sass/app.scss';
.section4__container {
  height: 550px;
  background-color: $main;
  margin: 0 auto;
  position: relative;
}
.section4__content {
  max-width: var(--max-width-layout);
  height: 400px;
  width: 95%;
  margin: auto;
}
@media screen and (max-width: $desktop-large) {
  .section4__container {
    height: 750px;
  }
  .section4__content {
    height: 600px;
  }
}
