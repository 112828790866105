.freelancer__card {
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 100%;

  &__side {
    height: 100%;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 2rem;
    overflow: hidden;
    border: 1px solid #fff;

    &--front {
      background-color: $main;
    }

    &--back {
      transform: rotateY(180deg);
    }
  }

  &:hover &__side {
    transform: rotateY(180deg);
  }

  &:hover &__side--front {
    transform: rotateY(-180deg);
  }

  &:hover &__side--back {
    transform: rotateY(0);
  }
}
