// Skills elements
@mixin skills-element {
  font-family: $main-font;
  background-color: $primary-back;
  padding: 3px 7px;
  border-radius: $button-radius;
  border-color: $primary-back;
  margin: 3px 6px 3px -2px;
  font-size: 12px;
}
