@import '@sass/app.scss';

.freelancer-project__chat-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
}

.freelancer-project__chat-wrapper {
  margin-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-radius: $card-radius;
}

.freelancer-project__files-wrapper {
  background-color: white;
  padding: 10px;
  border-radius: $card-radius;
  hr {
    margin: 2px;
  }
}

.freelancer-project__files-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  background-color: white;
  padding: 10px;
  border-radius: 0.8rem;
}
