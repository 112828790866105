@import '@sass/app.scss';

.new-card-window__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 80vh;
}

.new-card-window__container::-webkit-scrollbar {
  display: none;
}
.new-card-window_section {
  width: 100%;
  margin-bottom: 15px;
  textarea {
    width: 100%;
  }
  .ant-select {
    width: 100%;

    .ant-select-selector {
      border-radius: 0.8rem;
    }
  }
}

.new-card-window_section-content {
  display: flex;
  input {
    flex: 1;
  }
  p {
    margin: 5px;
    flex: 1;
  }
}

#board-item-priority {
  width: 100%;
  border-radius: $card-radius;
  padding: 5px;
  border: 1px solid var(--popup-background-color);
}
