.payment-option__container {
  flex: 1;
  display: flex;
  flex-direction: column;
  .tn-text {
    margin: auto;
  }
  &.selected {
    filter: invert(44%) sepia(58%) saturate(3913%) hue-rotate(204deg) brightness(104%)
      contrast(101%);
  }
}

.payment-option__icon {
  margin: auto;
  width: 40px;
}

.payment-option__container:hover {
  filter: invert(44%) sepia(58%) saturate(3913%) hue-rotate(204deg) brightness(104%) contrast(101%);
}
