@import '@sass/app.scss';

.tn-layout {
  display: flex;
  margin: 10px auto;
  min-height: 400px;
  width: 95%;
  max-width: 1160px;
  justify-content: space-around;
  align-items: flex-start;
  overflow: visible;
}
.tn-layout__right {
  flex: 1;
  padding-left: 20px;
  width: 30%;
  position: sticky;
  top: 80px;

  &.with-guide {
    max-width: 200px;
    min-width: 200px;
  }
  @media screen and (max-width: $desktop-medium) {
    display: none;
  }
}
.tn-layout__left {
  flex: 1;
  margin-right: 20px;
  position: sticky;
  top: 80px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}

.tn-layout__left::-webkit-scrollbar {
  display: none;
}

.tn-layout__left-section {
  border-radius: 0.8rem;
  background-color: white;
  margin-bottom: 15px;
  padding: 20px;
  @media screen and (max-width: $mobile-xlarge) {
    border: 1px solid var(--popup-background-color);
    padding: 10px;
  }
}
.tn-layout__left-nobackground {
  width: 35%;
  padding: 20px;
  margin-right: 20px;
  position: sticky;
  border-radius: 0.8rem;
}

.tn-layout__content {
  flex: 3;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  &.full-width {
    max-width: 100%;
  }
  @media screen and (max-width: $mobile-large) {
    width: 100%;
    max-width: 100%;
  }
  @media screen and (max-width: $desktop-medium) {
    flex: 2;
  }
}

.tn-layout__content::-webkit-scrollbar {
  display: none;
}

.tn-layout__margins {
  margin: 10px auto 20px auto;
  width: 80vw;
  max-width: 1160px;
  width: 95%;

  .ant-breadcrumb {
    margin-bottom: 10px;
  }
  .ant-breadcrumb-link {
    svg {
      width: 15px;
      margin: 0px 2px 3px 0px;
    }
  }
}

.ant-drawer-header {
  background-color: $main;
}
