@import '@sass/app.scss';

.row {
  display: flex;
}

.row p:first-child {
  flex: 1;
}

.row p:last-child {
  flex: 2;
}

.integrations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}

.item {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  max-width: 120px;
  height: 80px;
  display: flex;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  margin: 5px auto;
  flex: 45%;
  img {
    max-width: 90px;
    max-height: 40px;
    margin: auto;
  }
}

.documentation {
  display: flex;
  a {
    margin: auto;
  }
}
