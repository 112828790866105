@import '@sass/_animations.scss';

.background-loader {
  align-items: center;
  justify-content: center;
  width: 100%;
}
.background__files {
  align-items: center;
  justify-content: center;
  height: 82px;
  width: 100%;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader > span {
  display: inline-block;
  background-color: var(--primary-color);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  margin: 0px 8px;
  transform: translate3d(0);
  animation: bounce 0.5s infinite alternate;
}

.loader > span:nth-child(2) {
  background-color: var(--global-color-main);
  animation-delay: 0.2s;
}
.loader > span:nth-child(3) {
  background-color: var(--global-color-secondary);
  animation-delay: 0.4s;
}

.loaderSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loaderSecondary > span {
  display: inline-block;
  background-color: var(--global-color-secondary);
  width: 0px;
  height: 0px;
  border-radius: 50%;
  margin: 0px 8px;
  transform: translate3d(0);
  animation: bounce 0.5s infinite alternate;
}

.loaderSecondary > span:nth-child(2) {
  background-color: var(--global-color-secondary);
  animation-delay: 0.2s;
}
.loaderSecondary > span:nth-child(3) {
  background-color: var(--global-color-secondary);
  animation-delay: 0.4s;
}
