@import '@sass/app.scss';

.shade {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  margin-right: 8px;
  padding: 2px;
}

.shade:hover {
  background-color: $main;
  opacity: 0.5;
}

.radio {
  border-radius: 50%;
  background-color: $primary-back;
  width: 20px;
  height: 20px;
  padding: 4px;
  margin: auto;
}

.selected {
  background-color: $main;
  border-radius: 100%;
  height: 100%;
}
