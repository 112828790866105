.user-stats__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-stats__name-verified {
  display: flex;
  margin: auto;
  .user-stats__verficated-icon {
    height: 20px;
    width: 20px;
  }
}

.user-stats__star-rate {
  margin: auto;
}

.user-stats__profile {
  display: flex;
  margin: 10px;
}

.user-stats__profile-image {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
}
.user-stats__profile-image-drawer {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: auto;
}
