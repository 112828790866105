@import '@sass/app.scss';

.template {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    max-width: 200px;
    border-radius: 1em;
    margin: auto;
  }
  @media screen and (max-width: $mobile-xlarge) {
    padding: 0;
  }
}

.pallete {
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    max-width: 200px;
    border-radius: 1em;
    margin: auto;
  }
  @media screen and (max-width: $mobile-xlarge) {
    padding: 0;
  }
}

.urls {
  padding: 10px;
  a {
    margin-left: 10px;
    margin-bottom: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  @media screen and (max-width: $mobile-xlarge) {
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.empty {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 120px;
  height: 120px;
  display: flex;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;
  margin: auto;
  img {
    max-width: 90px;
    max-height: 50px;
    margin: auto;
  }
}
