.public-project__propousal-button {
  display: flex;
  button {
    margin: 20px auto;
    width: 300px;
  }
}

.public-project__question-container {
  margin-top: 10px;
}
