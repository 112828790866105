@import '@sass/app.scss';

.container {
  display: flex;
  border-radius: $button-radius;
  height: 40px;
  width: 100%;
  font-family: $main-font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: $mobile-xmedium) {
    display: none;
  }
}

.container span {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
  display: flex;
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;
  color: white;
}

.optionLabel {
  width: 50%;
  margin: 0;
}

input:checked + span {
  color: $secondary-back;
  background-color: $secondary;
  border-radius: $button-radius;
}

input[type='radio'] {
  visibility: hidden;
  position: absolute;
}
