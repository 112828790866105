@import '@sass/app.scss';

.title {
  font-family: $main-font-medium;
  font-size: 25px;
  color: $main;
  font-weight: bold;
  letter-spacing: 4px;
  margin-top: 10px;
  text-align: center;
  height: 20px;
}

.cms {
  width: 220px;
  display: flex;
  flex-direction: column;
  button {
    border: 1px solid rgb(217, 216, 216);
    border-radius: 1em;
    display: flex;
    height: 120px;
    .image {
      background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/cms-grey.png')
        no-repeat;
      background-position: center;
      height: 120px;
      width: 200px;
    }
  }

  &:hover {
    button {
      opacity: 1;
      scale: 1.1;
      transition: 200ms;
      border: 1px solid $main;
      .image {
        background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/cms-blue.png')
          no-repeat;
        background-position: center;
      }
    }
    p {
      color: $main;
    }
  }
}

.integrations {
  display: flex;
  flex-direction: column;
  width: 220px;
  button {
    border: 1px solid rgb(217, 216, 216);
    border-radius: 1em;
    display: flex;
    height: 120px;
    .image {
      background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/integration-grey.png')
        no-repeat;
      background-position: center;
      background-size: contain;
      height: 120px;
      width: 200px;
    }
  }

  &:hover {
    button {
      opacity: 1;
      scale: 1.1;
      transition: 200ms;
      border: 1px solid $main;
      .image {
        background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/integration-blue.png')
          no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
    p {
      color: $main;
    }
  }
}

.other {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 220px;

  button {
    border: 1px solid rgb(217, 216, 216);
    border-radius: 1em;
    display: flex;

    height: 120px;

    .image {
      background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/other-grey.png')
        no-repeat;
      background-position: center;
      height: 120px;
      width: 200px;
    }
  }

  &:hover {
    button {
      opacity: 1;
      scale: 1.1;
      transition: 200ms;
      border: 1px solid $main;
      .image {
        background: url('https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/other-blue.png')
          no-repeat;
        background-position: center;
      }
    }
    p {
      color: $main;
    }
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 2em;
  gap: 3em;
  flex-wrap: wrap;
}
