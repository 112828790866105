.profile-section__container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.profile-section__text {
  margin: 15px 5px;
  .clamp-lines .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
  }
}
