@import '@sass/app.scss';

.blue_circle {
  height: 12px;
  width: 12px;
  background-color: #0181ff;
  border-radius: 50%;
}
.white_circle {
  height: 12px;
  width: 12px;
  background-color: #00d5a1;
  border-radius: 50%;
}
.header {
  background-color: $main;
  border-radius: 10px 10px 0px 0px;
  height: 30px;
}
.chats_container {
  padding: 8px;
  font-family: $main-font-light;
}
.chats_items_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.chat_item {
  transition: all;
  padding: 4px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  img {
    width: 38px;
    border-radius: 100%;
  }
}
.opened_chat_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $main-font-light;
}
.opened_chat_title {
  border-bottom: 1px solid #cecece;
  height: 70px;
  padding: 12px 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  img {
    width: 40px;
    border-radius: 100%;
  }
  h2 {
    margin: 0;
    padding: 0;
  }
}
.chat_container {
  display: flex;
}
.chat_content {
  width: 100%;
  height: 300px;
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
  padding: 8px 12px;
}

.chat_items_search {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 4px 16px;
  height: 50px;
  align-items: center;
  border-top: 1px solid #cecece;
  border-radius: 0px 0px 10px 10px;
  font-family: $main-font-light;

  input {
    border: 0;
    outline: 0;
    background-color: #f2f2f2;
    width: 100%;
  }
  @media (max-width: 1024px) {
    border-radius: 0px;
    border-top: 0px;
  }
}
.chat_search {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  padding: 8px 16px;
  height: 50px;
  align-items: center;
  border-radius: 0px 0px 0px 10px;
  border-top: 1px solid #cecece;
  input {
    border: 0;
    outline: 0;
    background-color: #f2f2f2;
    width: 100%;
  }
  @media (max-width: 480px) {
    border-radius: 0px 0px 10px 10px;
  }
}
.chat_search_items {
  display: flex;
  gap: 4px;
  color: #3383ff;
}
.message_sent_container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 8px;
  gap: 8px;
  word-break: break-word;
  font-size: 12px;
}
.message_received_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  word-break: break-word;
  font-size: 12px;
}
.message_sent {
  background-color: #f2f2f2;
  padding: 4px 8px;
  border-radius: 10px;
}

.message_received {
  background-color: #0181ff;
  padding: 4px 8px;
  color: white;
  border-radius: 10px;
}
.messages {
  display: flex;
  flex-direction: column-reverse;
}
.underline_hover {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.unreaded_msgs {
  position: absolute;
  background-color: #ff4d4f;
  color: white;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  top: -7px;
}
