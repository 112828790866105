@import '@sass/app.scss';

.view-project-drawer__scroll {
  padding: 0px 24px;
  .clamp-lines p {
    white-space: pre-wrap;
    font-size: 0.8rem;
  }
  div .clamp-lines__button {
    border: none;
    outline: 0;
    float: right;
  }
}

.view-project-drawer__experience {
  padding-top: 40px;
}

.view-project-drawer__questions {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.view-project-drawer__questions > img {
  margin: auto;
  width: 200px;
}

.view-project-drawer__question-header {
  margin-top: 30px;
  display: flex;
  img {
    width: 30px;
    border-radius: 50%;
  }
  .tn-text {
    margin: auto 10px;
  }
}

.view-project-drawer__question-answer {
  margin-left: 40px;
  display: flex;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .tn-text {
    margin: auto 10px;
  }
}
.view-project-drawer__categories {
  display: flex;
  flex-direction: column;
  .tn-text {
    margin-bottom: 10px;
  }
}

.view-project-drawer__subcategories {
  display: flex;
}

.view-project-drawer__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.view-project-drawer__blue-background {
  background-color: $main;
  height: 60px;
}

.view-project-drawer__header {
  padding: 5px 24px;
}

.view-project-drawer__content {
  flex: 1;
  overflow: auto;
  height: auto;
}
