@import '@sass/app.scss';

.window-container {
  padding: 5px 15px;
}
.window-rate-user-image {
  display: flex;
  justify-content: center;
}
.window-rate-user-description {
  display: flex;
  justify-content: center;
}
.window-rate-user-description-ready {
  display: flex;
  justify-content: center;
  align-items: center;
}
.window-container h2 {
  margin-bottom: 20px;
  color: $main;
  font-family: $main-font;
}
.window-container h3 {
  font-size: 18px;
}
.rate-container {
  margin-bottom: 15px;
}
.window-rate-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.window-container textarea {
  resize: none;
  color: $primary-text;
}
