@import '@sass/app.scss';

.contact-drawer__button {
  display: flex;
  button {
    margin: auto;
  }
}

.contact-drawer__project-item {
  color: rgba(0, 0, 0, 0.8);
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.8rem;
  font-size: 15px;
  text-align: center;
  cursor: pointer;

  &--selected {
    color: rgba(0, 0, 0, 0.8);
    min-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.8rem;
    font-size: 15px;
    text-align: center;
    background-color: $menu-hover;
    cursor: pointer;
  }
}
.contact-drawer__project-item:hover {
  background-color: $background-color;
}

.contact-drawer__invite-button {
  display: flex;
  flex-direction: column;
  button {
    margin: 20px auto;
  }
  img {
    width: 250px;
    margin: auto;
  }
  p {
    margin: auto;
  }
}

.contact-drawer__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contact-drawer__green-background {
  background-color: $secondary;
  height: 60px;
}

.contact-drawer__content {
  flex: 1;
  overflow: auto;
  height: auto;
  padding: 10px 24px;
}
