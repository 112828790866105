@import '@sass/app.scss';
.section7__container {
  height: 650px;
  background-color: $main;
  @media screen and (max-width: $desktop-small) {
    .section7__container {
      height: 45em;
      background-color: $main;
    }
  }
}
.section7__content {
  max-width: var(--max-width-layout);
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $desktop-small) {
    .section7__content {
      flex-direction: column;
      padding-top: 100px;
    }
  }
}
.content__left {
  flex: 0 1 500px;
  @media screen and (max-width: $desktop-small) {
    .content__left {
      flex-basis: 100px;
      width: 80%;
    }
  }
}
.content__right {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  width: 680px;
  @media screen and (max-width: $desktop-small) {
    .content__right {
      width: 500px;
    }
  }
}
