@import '@sass/app.scss';

.registration-confirmation-input-container {
  margin: 1rem 0 3.5rem 0;
}

.registration-confirmation-input-container button {
  margin-top: 1rem;
}

.registration-confirmation-input {
  line-height: 30px;
  font-weight: bolder;
  margin-top: 10px;
  width: 80% !important;
  margin-left: 10%;
}

.register-confirmation-title {
  text-align: center;
}

.registration-resend-container {
  display: flex;
  max-width: 80%;
  margin: auto;
  margin-bottom: 15px;
}

.registration-resend-button {
  background-color: $secondary-back;
  color: $details-text;
  border-radius: 0.8rem;
  border-color: lightgray;
  max-width: 100px;
  outline: 0;
  font-family: $main-font;
  font-size: 15px;
  letter-spacing: 0.5px;
  transition: background-color 200ms;
  &:hover {
    color: $secondary-back;
    background-color: $details-text;
  }
}

.registration-resend-input {
  line-height: 27px;
}

.ant-input-password {
  border-radius: 0.8rem !important;
}

.ant-input {
  border-radius: 0.8rem;
}

.forgot-password-input {
  margin-bottom: 30px;
}
