$blue-color: #3383ff;
$green-color: #00d5a1;

.project_steps_main {
  .wrapper {
    font-family: 'Helvetica';
    font-size: 14px;
    padding-bottom: 0;
  }
  .StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
  }
  .StepProgress::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  .StepProgress-item {
    position: relative;
    counter-increment: list;
  }
  .StepProgress-item:not(:last-child) {
    padding-bottom: 20px;
  }

  .StepProgress-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -28px;
    height: 100%;
    width: 10px;
    border-left: 2px solid #cccc;
  }
  .StepProgress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -37px;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
  }
  .StepProgress-item.finished::before {
    border-left: 2px solid $green-color;
  }
  .StepProgress-item.finished::after {
    font-size: 13px;
    color: #fff;
    text-align: center;
    border: 2px solid $green-color;
    background-color: $green-color;
  }

  .StepProgress-item.started::before {
    border-left: 2px solid $green-color;
  }
  .StepProgress-item.started::after {
    content: '';
    padding-top: 1px;
    width: 25px;
    height: 25px;
    top: -4px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    color: $green-color;
    border: 2px solid $green-color;
    background-color: white;
  }
  // .StepProgress-item.started:hover::after {
  //   scale: calc(110%);
  // }
  .StepProgress span {
    display: block;
    font-size: 16px;
  }
  .StepProgress-item button {
    border: 1px solid $blue-color;
    font-size: 14px;
    margin: 0.5em 0;
  }
  .StepProgress-item button:hover {
    border: 1px solid $green-color;
    color: $green-color;
  }
  //project start y end
  .StepProgress-item.project-start::before {
    border-left: 2px solid $green-color;
  }
  .StepProgress-item.project-start::after {
    content: '';
    padding-top: 1px;
    width: 25px;
    height: 25px;
    top: -4px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    color: $blue-color;
    border: 2px solid $blue-color;
    background-color: white;
  }
  .StepProgress-item.project-end::before {
    border-left: 0;
  }
  .StepProgress-item.project-end::after {
    font-size: 13px;
    color: #fff;
    text-align: center;
    border: 2px solid $blue-color;
    background-color: $blue-color;
  }
  .not-started {
    color: rgba(0, 0, 0, 0.45);
  }
  .details {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px !important;
  }
  .show_feedback {
    color: #3383ff;
    font-size: 12px !important;
    margin: 0;
    cursor: pointer;
    &:hover {
      color: #00d5a1;
    }
  }
}
