/*ICON FILE CSS*/
.container {
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 120px;
  margin: 0px;
  text-align: center;
}

.utils-file-extension {
  word-break: break-all;
  font-size: 11px;
  text-decoration: none;
  color: grey;
  line-height: 12px;
  margin-top: 5px;
}
.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 45px;
  height: 65px;
  font-size: 35px;
  margin: 5px auto;
  i {
    font-size: 40px;
    margin: auto 0px auto 25px;
    background-color: white;
  }
  img {
    width: 30px;
    border-radius: 100%;
    position: relative;
    top: 15px;
    right: 15px;
  }
}

.infoContainer {
  max-width: 15ch;
  overflow: hidden;
  word-break: break-all;
  font-size: 11px;
  text-decoration: none;
  color: grey;
  line-height: 12px;
  margin: 5px auto;
}
