@import '@sass/app.scss';

.messagesSearchBar {
  margin-bottom: 10px;
  margin-top: 5px;
  height: 35px;
  font-size: 17px !important;
  color: $primary-text;
}
.search-bar-input-messages {
  display: flex;
  background-color: #fff;
  border-radius: $button-radius;
  max-width: 95%;
  padding-left: 10px;
  border: 1px solid lightgray;
}
.search-bar-button-messages {
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: background-color 0.3s ease;
  border-top-right-radius: $button-radius;
  border-bottom-right-radius: $button-radius;
  &:hover {
    background-color: $primary !important;
    svg {
      color: #fff;
    }
  }
  svg {
    margin-top: -5px;
    color: $primary;
    font-size: 20px;
  }
}
