@import '@sass/app.scss';

.project-card__profile {
  display: flex;
  flex-direction: column;
  flex: 3;
  .project-card__content {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
  }
}

.project-card__blue-header {
  background-color: $main;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
}

.project-card__green-header {
  background-color: $secondary;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
}

.project-card__header {
  max-height: 50px;
  display: flex;
  p {
    margin: 10px;
  }
}

.project-card__profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: auto;
}
.project-card__user-stats {
  margin: 10px;
}
.project-card__project {
  flex: 4;
  .project-card__content {
    display: flex;
    flex-direction: column;
    margin: 10px 0px 10px 10px;
    @media screen and (max-width: $mobile-xlarge) {
      padding-left: 20px;
      margin: 0px;
    }
  }
}

.project-card__categories {
  display: flex;
  flex-direction: column;
}

.project-card__buttons {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.project-card__subcategories {
  display: flex;
  flex-wrap: wrap;
}

.project-card__requester-header {
  margin-top: 10px;
}
.project-card_proposal_modal-about-me {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media (max-width: 870px) {
  .project-card_proposal_modal-about-me {
    grid-template-columns: 1fr;
  }
}
