@import '@sass/app.scss';

.more-configurations__button {
  width: 100%;
  margin-bottom: 10px;
  border: none;
  color: black;
  letter-spacing: 2px;
  text-align: left;
  padding-left: 20px;
  height: auto;
  transition: all ease 0.5s;
  font-size: 17px;
  line-height: 40px;

  &:hover {
    background-color: lightgray;
  }
}
.more-configurations__button--active {
  width: 100%;
  margin-bottom: 10px;
  border: none;
  color: black;
  letter-spacing: 2px;
  text-align: left;
  padding-left: 20px;
  height: auto;
  transition: all ease 0.5s;
  font-size: 17px;
  line-height: 40px;
  color: $main;
  background-color: #fff;

  &:hover {
    background-color: lightgray;
  }
}
.more-configurations__button,
.more-configurations__button--active {
  display: flex;
  align-items: center;
  gap: 1em;
}
