@import '@sass/app.scss';

.register-important-info {
  font-family: $main-font;
  margin-top: 5px;
  color: $main;
  text-align: justify;
  font-size: 12px;
  width: 400px;
}
