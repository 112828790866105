@import '@sass/app.scss';

.container {
  width: 100%;
  display: flex;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 1250px;
  padding: 5em 10px 5em 10px;
}

.imgContainer {
  display: flex;
  cursor: pointer;
  img {
    width: 500px;
    margin: 20px auto -20px auto;
    @media screen and (max-width: $mobile-xlarge) {
      width: 300px;
    }
  }
}

.postContainer {
  background-color: white;
  border-radius: $card-radius;
  margin-bottom: 20px;
}

.image {
  margin-bottom: 20px;
  img {
    width: 100%;
    border-radius: $card-radius;
    object-fit: cover;
  }
}

.whiteContainer {
  background-color: white;
}

.postContainer title {
  display: flex;
  text-align: center;
  max-width: 940px;
  margin: auto;
  h3 {
    margin: 0px auto;
  }
}
.postsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.recommendsPosts {
  max-width: 940px;
  margin: 0 auto;
  padding-bottom: 5em;
}
.postCard {
  width: 32.15%;
  @media screen and (max-width: $desktop-medium) {
    width: 48.5%;
  }
  @media screen and (max-width: $mobile-xlarge) {
    width: 100%;
  }
}

.tags {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.icons {
  color: lightgray;
}

.item {
  display: flex;
  margin: 0px auto;
  p {
    margin: auto 5px;
  }
}

.abstract {
  margin-bottom: 20px;
  max-width: 940px;
  margin: auto;
  text-align: justify;
  text-justify: inter-word;
  p {
    color: $primary-text;
    font-family: $main-font-light;
    font-size: 1rem;
  }
}
.text {
  max-width: 940px;
  margin: auto;
  text-align: justify;
  text-justify: inter-word;

  p {
    color: $blog-text;
    font-family: $main-font-light;
    font-size: 1rem;

    strong {
      font-size: 1rem;
      color: $primary-text;
      font-family: $main-font;
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 350px;
      width: 100%;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $main-font;
  }
}
