.form-account__row {
  display: flex;
  p {
    flex: 1;
  }
  .ant-row {
    flex: 1;
  }

  .select-bank-account-form {
    flex: 1;
    width: 100%;
    .ant-select-selector {
      border-radius: 0.8rem;
    }
  }
  button {
    margin: auto;
  }
}

.form-account_row {
  display: flex;
  p {
    margin-left: 10px;
    margin-bottom: 5px;
  }
}

//remove input type number arrows

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input,
.ant-select-selector {
  border-radius: 1em !important;
}
