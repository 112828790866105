.content {
  height: 55vh;
  overflow-y: scroll;
}

.description {
  button {
    border: none;
    outline: 0;
    float: right;
  }
  margin-bottom: 40px;
  margin-top: 20px;
}
.item {
  margin-bottom: 20px;
}

.title {
  display: flex;
  h3 {
    margin: auto;
  }
}

.buttonContainer {
  display: flex;
  button {
    margin: auto;
  }
}
