.form {
  display: flex;
  gap: 8px;
}

.prefixSection {
  width: 30%;
}
.numberSection {
  width: 70%;
}
