@import '@sass/app.scss';

.filters {
  display: flex;
  margin-bottom: 10px;
}

.categories {
  @media (max-width: $desktop-small) {
    display: none;
  }
}

.orderBy {
  margin-left: auto;
  margin-right: 0;
}

.headerButtons {
  display: none;
  button {
    margin: auto;
    width: 200px;
  }
  hr {
    border: 1px solid '#ebebeb';
    width: 90%;
    margin: 10px auto;
  }
  @media (max-width: $desktop-small) {
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
}
