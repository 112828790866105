.new_chat_section {
  * {
    outline: 0;
    box-sizing: inherit;
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }

  html {
    height: 100%;
    box-sizing: border-box;
  }

  body {
    height: 100%;
    color: #3b414d;
    font-family: sans-serif;
    letter-spacing: 0.03em;
  }

  h3 {
    font-size: 15px;
    font-weight: 600;
  }

  p {
    font-size: 82%;
  }

  input {
    &::-webkit-input-placeholder {
      color: #cccccc;
    }
    &::-moz-placeholder {
      color: #cccccc;
    }
    &:-ms-input-placeholder {
      color: #cccccc;
    }
    &:-moz-placeholder {
      color: #cccccc;
    }
  }

  .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: white;
    margin: 0;
    border-radius: 1em;
    padding-top: 0;
    border: solid 1px #ebebeb;
    border-radius: 0.8rem;
    hr {
      border: 1px solid #ebebeb;
      width: 100%;
      margin: 5px;
    }
  }

  .container {
    position: relative;
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }

  .col-left .messages {
    li {
      padding-top: 15px;
      width: 100%;
      display: block;
      overflow: hidden;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 15px;
      &:first-child {
        padding-top: 20px;
      }
      &:last-child {
        padding-bottom: 20px;
      }
      p {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    h3 {
      margin-bottom: 7px;
    }
    p {
      margin-bottom: 0px;
      color: #9197a5;
    }
  }

  main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .col-right {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 300px;
    flex: 0 0 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media (max-width: 1023px) {
    .col-right,
    .col {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }
  .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .col-content {
    -webkit-box-flex: 1;
    padding: 0px;
    -ms-flex: 1;
    flex: 1;
    overflow-y: auto;
  }

  .col-foot {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
  }

  .message-sent,
  .message-received {
    clear: both;
    position: relative;
  }

  .message-sent::before,
  .message-received::before,
  .message-sent::after,
  .message-received::after {
    content: '';
    display: table;
  }

  [class^='grid-'] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .grid-message > [class^='col-'] {
    margin-top: 1em;
    margin-right: 1em;
    &:nth-child(-n + 1) {
      margin-top: 0;
    }
    &:nth-child(1n) {
      margin-right: 0;
    }
  }

  .col-message-sent {
    margin-left: calc(8.33333333% + 0.08333333em) !important;
  }

  .col-message-received {
    margin-right: calc(8.33333333% + 0.08333333em) !important;
  }

  .col-message-sent,
  .col-message-received {
    width: calc(91.66666667% - 0.08235677em);
  }

  .message-sent,
  .message-received {
    margin-top: 0.3em;
    margin-bottom: 0.3em;
    padding: 0.5em 0.6em;
  }

  .message-sent p,
  .message-received p {
    margin: 0;
    line-height: 1.5;
  }

  .message-sent {
    float: right;
    color: white;
    background-color: #0181ff;
    border-radius: 0.5em 0.25em 0.25em 0.5em;
    &:first-child {
      border-radius: 0.5em 0.5em 0.25em 0.5em;
    }
    &:last-child {
      border-radius: 0.25em 0.25em 0.5em 0.5em;
    }
    &:only-child {
      border-radius: 0.5em;
    }
  }

  .message-received {
    float: left;
    color: black;
    background-color: #e6e6e6;
    border-radius: 0.25em 0.5em 0.5em 0.25em;
    &:first-child {
      border-radius: 0.5em 0.5em 0.5em 0.25em;
      margin-top: 0px;
    }
    &:last-child {
      border-radius: 0.25em 0.5em 0.5em 0.5em;
    }
    &:only-child {
      border-radius: 0.5em;
    }
  }

  .col-message-sent,
  .col-message-received {
    margin-top: 0.25em !important;
  }

  .message {
    padding: 20px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .compose {
    padding: 5px;
    position: relative;
    textarea {
      width: 100%;
      border: 0px;
      font-size: 15px;
      resize: none;
      padding: 10px 100px 10px 10px;
      height: 60px;
      max-height: 60px;
      background-color: #ebebeb;
      border-radius: 0.8rem;
    }
    .compose-dock .dock {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .compose-dock svg {
      margin-left: 8px;
      color: #0181ff;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .chat_file_container {
    background-color: white;
    border-radius: 0.5em;
    padding: 0.5em;
    text-align: center;
  }
  .stop-scroll {
    overflow: hidden;
  }
  .chat-tabs {
    display: flex;
    justify-content: center;
    gap: 6em;
    font-size: 16px;
    p {
      cursor: pointer;
    }
    .active {
      color: #0181ff;
    }
  }
}
