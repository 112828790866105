@import '@sass/app.scss';

.chat__container {
  min-height: 40vh;
  background-color: white;
  border-radius: 0.8rem;
}
.chat__bottom {
  width: 100%;
  display: flex;

  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: 1px solid lightgray;
  textarea {
    width: 100%;
  }
}
.chat__send-button {
  font-size: 16px;
  width: 40px;
  height: 38px;
  border-radius: 50%;
  color: white;
  background-color: var(--primary-color);
  border: none;
  margin: auto 5px;
  i {
    margin-right: 2px;
  }
}
