body,
html {
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins Italic';
  src: url('./assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --ftr-font-family-book: 'Poppins Regular';
  --ftr-font-family-book-italic: 'Poppins Italic';
  --ftr-font-family-light: 'Poppins Light';
  --ftr-font-family-medium: 'Poppins Medium';
}

::-webkit-scrollbar {
  width: 3px;
  overflow: overlay;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 5px;
}

.ant-modal-content {
  border-radius: 0.8rem;
}

.ant-rate .ant-rate-star {
  margin-right: 3px;
}
