.image {
  max-width: 100%;
}
.innerContainer {
  max-width: 400px;
  margin: 100px auto;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}
.button {
  margin: 20px auto;
}
