@import '@sass/app.scss';

.section5__section {
  height: 200px;
  background-color: #fbfbfb;
  margin: auto;
}
.section5__content {
  max-width: var(--max-width-layout);
  width: 95%;
  height: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 30px;
}
.section5__images {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
  width: 100%;
}
.section5_image {
  min-width: 200px;
}

@media screen and (max-width: $desktop-small) {
  .section5__section {
    height: 400px;
  }
  .section5__images {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .section5_image {
    width: 30%;
  }
}
