@import '@sass/app.scss';

.status-tag__container {
  position: relative;
  margin: auto 5px;
  min-width: 120px;
  border-radius: $button-radius;
  .status-tag__text {
    margin: 3px;
    text-align: center;
  }
}
