@import '@sass/app.scss';
.aboutus__layout {
  width: 100%;
  background-color: $secondary-back;
  overflow: hidden;
}
.aboutus__header {
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $mobile-medium) {
    height: 500px;
  }
}
.header__container {
  max-width: var(--max-width-layout);
  margin: 0 auto;
}
.header__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  @media screen and (max-width: $desktop-xsmall) {
    width: 80%;
  }
}
.header__title {
  text-align: center;
  margin-bottom: 20px;
  @media screen and (max-width: $mobile-medium) {
    width: 100%;
  }
}
.header__sentence {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  white-space: nowrap;

  @media screen and (max-width: $mobile-medium) {
    width: 100%;
    flex-direction: column;
    gap: 0;
  }
  #text {
    display: none;
  }
}
.about-us__members {
  margin: auto;
  max-width: var(--max-width-layout);
}

.about-us__description {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.about-us__image-ctn {
  flex: 1 1 50%;
  min-width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.about-us__image {
  max-width: 100%;
  @media screen and (max-width: $mobile-large) {
    max-width: 80%;
  }
}
.about-us__who-we-are {
  margin-top: 50px;
  text-align: center;
  @media screen and (max-width: $mobile-large) {
    margin-top: 50px;
  }
}
.about-us__team-member-card {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
}
.aboutus__info {
  height: 500px;
  max-width: var(--max-width-layout);
  margin: auto;
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $desktop-small) {
    height: 850px;
    width: 80%;
    text-align: center;
    padding: 0px;
  }
  @media screen and (max-width: $mobile-medium) {
    width: 100%;
    text-align: center;
    padding: 0px;
  }
}
.info__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: $desktop-small) {
    flex-direction: column;
    text-align: center;
  }
}
.info__left {
  flex: 0 1 40%;
  display: flex;
  flex-direction: column;
  margin-right: 40px;
  gap: 0.5rem;
  @media screen and (max-width: $desktop-xsmall) {
    margin: auto;
    width: 90%;
  }
  @media screen and (max-width: $mobile-medium) {
    margin: auto;
    width: 80%;
  }
}
.info__right {
  flex: 0 1 300px;
  @media screen and (max-width: $desktop-xsmall) {
    margin-top: 30px;
  }
  @media screen and (max-width: $mobile-medium) {
    width: 100%;
    text-align: center;
    padding: 0px;
    max-height: 350px;
  }
  @media screen and (max-width: $mobile-small) {
    width: 100%;
    text-align: center;
    padding: 0px;
    max-height: 250px;
  }
}
.icons__items {
  height: auto;
  background-color: $main;
  width: 100%;
  margin: auto;
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons__container__aboutus {
  max-width: var(--max-width-layout);
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: $mobile-xlmedium) {
    flex-direction: column;
  }
}
.icon__item {
  flex: 1 1 auto;
  text-align: center;
}
.icon__single {
  display: flex;
  justify-content: center;
  @media screen and (max-width: $mobile-xlmedium) {
    margin: 3% auto 3%;
  }
}
.icon__single svg {
  color: $secondary-back;
  height: 80px;
  margin-bottom: 20px;
}
.contact__us {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  @media screen and (max-width: $desktop-small) {
    height: fit-content;
  }
}
.contact_us-container {
  flex: 1 1 auto;
  max-width: 1000px;
  margin: auto;
}
