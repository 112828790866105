@import '@sass/app.scss';

.container {
  margin-right: 10px;
  @media screen and (max-width: $desktop-large) {
    margin-right: 0px;
  }
}

.content {
  background-color: white;
  border-radius: $card-radius;
  padding: 15px;
}

.content::-webkit-scrollbar {
  display: none;
}

.item {
  margin-bottom: 20px;
}
