@import '@sass/app.scss';

.switch-container {
  display: flex;
  border: 1px solid lightgray;
  background-color: $primary-back;
  border-radius: $button-radius;
  height: 40px;
  width: 100%;
  font-family: $main-font;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: $mobile-xmedium) {
    max-width: 90%;
    margin: auto;
  }
}

.switch-container span {
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 0;
}

input[id='work']:checked + span {
  color: $secondary-back;
  background-color: $primary;
  border-radius: $button-radius;
}

input[id='hire']:checked + span {
  color: $secondary-back;
  background-color: $secondary;
  border-radius: $button-radius;
}

.switch-container span {
  display: flex;
  border: none;
  cursor: pointer;
  transition: all 0.3s linear;
}

.switch-container span[id='work-label'] {
  color: $primary;
}

.switch-container span[id='hire-label'] {
  color: $secondary;
}

input[type='radio'] {
  visibility: hidden;
  position: absolute;
}
.hiring,
.working {
  width: 50%;
  margin: 0;
}
