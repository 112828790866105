@import '@sass/app.scss';
.blueHeader {
  background-color: $primary;
  height: 60px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 960px;
  width: 100%;
  margin: 1em auto;
  padding: 2em;
  height: 100%;
  background-color: white;
  border-radius: 1em;
  border: 1px solid rgba(128, 128, 128, 0.253);
  box-shadow: $box-shadow;
}

.button {
  margin: 2em 0;
  max-width: 250px;
  width: 100%;
}
.bg {
  // background-color: #3383ff;
  background-image: url('./assets/bg.png');
  padding: 1em 0;
  min-height: calc(100vh - 70px);
  background-repeat: no-repeat;
  background-position: center;

  background-size: cover;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1em 1em 1em;
}
.options {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // @media (max-width: $desktop-medium) {
  //   grid-template-columns: repeat(3, 1fr);
  // }
  // @media (max-width: $mobile-xlarge) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
  // @media (max-width: $mobile-small) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  margin: 2em 0;
  gap: 1em;
  min-height: 210px;
  p {
    font-weight: 900;
  }
  img {
    width: 150px;
    height: 100px;
    cursor: pointer;
    object-fit: contain;
    margin: 2em 0;
    border-radius: 0.5em;
    opacity: 0.65;
    border: 1px solid rgba(200, 200, 200, 0.5);
    &:hover {
      opacity: 1;
      scale: 1.1;
      transition: 200ms;
    }
  }

  @media (max-width: $mobile-xlmedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    img {
      width: 130px;
      height: 100px;
    }
  }

  @media (max-width: 340px) {
    display: flex;
  }
}
.option {
  min-height: 230px;
  width: 250px;

  @media (max-width: $mobile-xlmedium) {
    min-height: 230px;
    width: 200px;
  }
  @media (max-width: $mobile-xmedium) {
    min-height: 230px;
    width: 150px;
  }
}
.optionsVertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2em 0;
  gap: 1em;
  min-height: 210px;
  p {
    font-weight: 900;
  }
  img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    object-fit: contain;
    margin: 2em 0;
    border-radius: 99em;
    opacity: 0.65;
    border: 1px solid rgba(200, 200, 200, 0.5);
    &:hover {
      opacity: 1;
      scale: 1.1;
      transition: 200ms;
    }
  }
}
.optionVertical {
  text-align: left;
  padding: 0.5em 1em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  max-width: 480px;
  width: 100%;
  cursor: pointer;
  p {
    font-weight: 100 !important;
    font-size: 16px;
  }
}
.leftside {
  margin: 2em 0;
  background-color: white;
  border-radius: 1em;
}
.leftcontainer {
  padding: 1em;
}
.arrows {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 480px;
  padding: 2em;
  .enabled {
    font-size: 18px !important;
  }
  .disabled {
    font-size: 18px !important;
    background-color: #f2f2f2;
    border-radius: 100em;
    color: #d3d3d3;
  }
}

.select {
  span {
    display: none;
  }
}

.templates_container {
  padding: 3em 0 5em 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: $desktop-medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $desktop-xsmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobile-large) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 4em 2em;
  .template {
    img {
      width: 250px;
      height: 250px;
      object-fit: contain;
      cursor: pointer;
      border-radius: 1em;
      opacity: 0.65;
      &:hover {
        opacity: 1;
        scale: 1.05;
        transition: 200ms;
      }
    }
  }
}
.palettes_container {
  padding: 3em 0 5em 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: $desktop-medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: $desktop-xsmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $mobile-large) {
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 4em 2em;
  .palette {
    p {
      font-weight: 900;
    }
    img {
      width: 24 0px;
      height: 200px;
      object-fit: cover;
      cursor: pointer;
      opacity: 0.65;
      &:hover {
        opacity: 1;
        scale: 1.05;
        transition: 200ms;
      }
    }
  }
}
.textfield {
  margin-top: 2em;
  width: 100%;
  max-width: 480px;
  border: 0;
  border-bottom: 1px solid black;
  font-size: 18px;
  &:focus {
    outline: none;
  }
}
.description {
  h4 {
    font-weight: 900 !important;
  }
  p {
    font-size: 14px !important;
    padding: 0 2em;
  }
  @media (max-width: $mobile-xlmedium) {
    h4 {
      font-size: 24px !important;
    }
    p {
      font-size: 12px !important;
    }
  }
}
.url_container {
  display: flex;
  justify-content: center;
  margin: 3em 0em;
  flex-direction: column;
  align-items: center;
  gap: 3em;
  width: 100%;
  input {
    font-size: 18px !important;
  }
}
.w-100 {
  width: 100%;
}
