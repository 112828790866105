@import '@sass/app.scss';

.empty-list-card__container {
  background-color: white;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $mobile-large) {
    flex-direction: column;
  }
}

.empty-list-card__ilustration {
  flex: 1;
  display: flex;
  padding: 50px 0px;
  img {
    margin: auto;
    width: 270px;
  }
}

.empty-list-card__content {
  flex: 1;
  margin: 70px auto;
  @media screen and (max-width: $mobile-large) {
    display: flex;
    flex-direction: column;
    p {
      margin: auto;
    }
    button {
      margin: auto;
    }
  }
}
