@import '@sass/app.scss';

/*USER MESSAGES CSS*/
.user-message-ctn-wrapper {
  width: 90%;
  margin: auto;
}
.user-message-ctn {
  max-width: fit-content;
  margin-bottom: 25px;
}
.user-message-top {
  display: flex;
  flex-direction: row;
}
.user-message-description-ctn {
  max-width: 500px;
}

.user-message-profile-img {
  max-width: 35px;
  min-width: 35px;
  max-height: 35px;
  border-radius: 100%;
  margin-right: 10px;
  margin-bottom: 5px;
}

.user-message-description {
  font-size: smaller;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 5px 15px 5px 20px;
  background-color: var(--background-color);
  clip-path: polygon(0 0px, 10px 0, 100% 0, 100% 100%, 10px 100%, 10px 20px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  min-height: 27px;
  min-width: 50px;
}
.user-message-description-owner-true {
  clip-path: polygon(0 0px, 0 0, 100% 0, calc(100% - 10px) 20px, calc(100% - 10px) 100%, 0 100%);
  border-radius: 8px 8px 0px 0px;
}
.is-owner-img-true {
  margin-left: 10px;
}
.user-msg-isOwner-true {
  float: right;
}
.user-messsage-description-date {
  font-family: $main-font-light;
  font-size: 10px;
  margin-left: 10px;
}
.user-message-files-ctn hr {
  background-color: #fff !important;
}
.user-message-files-ctn {
  padding-left: 10px;
  padding-right: 30px;
}
.user-message-files {
  display: flex;
  flex-wrap: wrap;
}
.user-message-files-title {
  display: flex;
  font-size: 12px;
  margin-top: 7px;
}
.user-message-files-title p {
  margin-left: 7px;
  font-size: 12px !important;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: $main-font;
  letter-spacing: 0px;
}
