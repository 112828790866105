@import '@sass/app.scss';

.custom-drawer__blue-background {
  background-color: $main;
  height: 60px;
}

.custom-drawer__container-main {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-drawer-header {
    background-color: $main;
  }
}
.custom-drawer__container-secondary {
  max-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .ant-drawer-header {
    background-color: $secondary;
  }
}

.ant-drawer-content {
  overflow: visible;
}

.ant-drawer-close .anticon-close {
  color: white;
}
