@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: $card-radius;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  position: relative;
  @media screen and (max-width: $mobile-xlarge) {
    flex-direction: column;
  }
  @media screen and (max-width: $desktop-small) {
    padding-bottom: 20px;
  }
}

.left {
  flex: 1;
  padding: 10px 0px 0px 20px;
  display: flex;
  hr {
    margin: 40px 5px 0px 5px;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    height: 60%;

    @media screen and (max-width: $desktop-small) {
      display: none;
    }
  }
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}

.right {
  flex: 2;
  padding: 10px 20px 0px 0px;

  max-width: 425px;

  word-break: break-all;

  @media screen and (max-width: $mobile-xlarge) {
    padding: 20px;
    flex-direction: column;
  }
}

.buttons {
  flex: 1;
  display: flex;
  margin-top: 10px;
}

.bottom {
  width: 100%;
  margin-top: 30px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
