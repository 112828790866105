// Main fonts
$main-font: 'Poppins Regular';
$main-font-italic: 'Poppins Italic';
$main-font-light: 'Poppins Light';
$main-font-medium: 'Poppins Medium';

//Font sizes
$title: 25px;
$subtitle: 20px;
$text: 17px;
$detail: 14px;
