@import '@sass/app.scss';

.container {
  border-radius: $card-radius;
  @media screen and (max-width: $desktop-large) {
    margin-right: 0px;
  }
}

.imagesContainer {
  width: auto;
  margin-right: auto;
  display: inline-block;
  margin-bottom: 20px;
}

.images {
  background-color: white;
  border-radius: $card-radius;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.details {
  background-color: white;
  border-radius: $card-radius;
  padding: 15px;
}

.images > div {
  margin: 5px;
}
.images > div > img {
  border: 2px solid $main;
}

.section {
  margin-top: 15px;
}
