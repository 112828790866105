@import '@sass/app.scss';

.mycarousel-container {
  position: relative;
  flex: 0 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: middle;
  box-shadow: none;
  z-index: 0;
}
.mycarousel-card {
  display: flex;
  flex-direction: row;
  height: 300px;
  width: 450px;
  text-align: center;
  background: #fff;
  border-radius: 1rem;
}
.mycarousel-image-container {
  text-align: center;
  justify-content: center;
  margin: auto;
  padding: 8px;
  border-radius: 100%;
  background-color: white;
}
.mycarousel__feed {
  max-width: 300px;
  padding: 20px;
  text-align: left;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.mycarousel__title {
  margin: 0px;
}
.mycarousel__header {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.checked__user {
  background-color: $primary;
  color: white;
  padding: 6px;
  border-radius: 50%;
  margin-left: 10px;
}
.mycarousel__profesion {
  margin: 5px 0px 20px;
}
.mycarousel__image {
  border-radius: 5rem;
  overflow: hidden;
}
@media screen and (min-width: $mobile-small) and (max-width: $mobile-xlarge) {
  .mycarousel-card {
    flex-direction: column;
    height: 550px;
    width: 270px;
  }
  .mycarousel__feed {
    text-align: center;
    width: 100%;
  }
}
