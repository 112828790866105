@import '@sass/app.scss';

.publication-card__blue-header {
  background-color: $primary;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  display: flex;
}

.publication-card__edit {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 2rem;
}

.proyect-card__deactivate {
  pointer-events: none;
  opacity: 0.6;
}
