@import '@sass/app.scss';

.sidepanel {
  float: left;
  min-width: 280px;
  max-width: 340px;
  width: 100%;
  height: 100%;
  background: var(--background-color-light);
  color: #f5f5f5;
  overflow: hidden;
  position: relative;
}
.tn-chat-contactss {
  margin-top: 45px;
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.tn-chat-contactss.expanded {
  height: calc(100% - 334px);
}
.tn-chat-contactss::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.tn-chat-contactss::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.tn-chat-contactss ul li.contact {
  cursor: pointer;
  background-color: #fff;
  border-radius: 0.8rem;
  border: 2px solid lightgray;
  margin-bottom: 10px;
  list-style-type: none;
  .user-card-body {
    width: 100%;
    .user-card-data {
      h5 {
        font-size: 16px;
      }
      h6 {
        font-size: 15px;
      }
    }
  }
}
.preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease;
}
.preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5;
}
.contactss-list {
  margin: 0;
  padding: 10px 10px 10px 0;
}
.bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
}

.bottom-bar button:focus {
  outline: none;
}
.bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50;
}

.bottom-bar button:hover {
  background: #435f7a;
}
.bottom-bar button i {
  margin-right: 3px;
  font-size: 1em;
}
@media screen and (max-width: $mobile-small) {
  .frame {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: $mobile-large) {
  .sidepanel {
    width: 100px;
    min-width: 100px;
  }

  .sidepanel .tn-chat-contactss {
    height: calc(100% - 149px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .sidepanel .tn-chat-contactss::-webkit-scrollbar {
    display: none;
  }

  .sidepanel .tn-chat-contactss ul li.contact {
    padding: 6px 0 66px 8px;
  }

  .sidepanel .bottom-bar button {
    float: none;
    width: 100%;
    padding: 15px 0;
  }

  .sidepanel .bottom-bar button:nth-child(1) {
    border-right: none;
    border-bottom: 1px solid #2c3e50;
  }

  .sidepanel .bottom-bar button i {
    font-size: 1.3em;
  }

  .sidepanel .bottom-bar button span {
    display: none;
  }
  .tn-chat__content .messages {
    max-height: calc(100% - 105px);
  }
  .tn-chat__content .messages ul li p {
    max-width: 350px;
  }

  .tn-chat__content .message-input .wrap button {
    padding: 21px 0;
  }
}
// HERE STARTS DE CHAT
.tn-chat__content {
  min-height: 750px;
  height: 750px;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: $desktop-xsmall) {
}
.chat-separation {
  height: 2%;
  background-color: $primary-back;
}
.contact-profile {
  width: 100%;
  height: 75px;
  flex: 0 1 8%;
  line-height: 80px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 0.8rem;
}
.contact-profile-img {
  height: 55px;
  border-radius: 50%;
  float: left;
  margin: 9px 9px 9px 15px;
}
.contact-profile-header {
  font-size: 16px;
  width: 100%;
  height: 20%;
  margin-top: -20px;
}
.contact-profile-name {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 3px;
  width: 100%;
  height: 1%;
}

.contact-profile-mid-section {
  width: 90%;
  display: flex;
}
.contact-profile-mid-left {
  display: flex;
  width: 60%;
  height: 100%;
  flex-direction: column;
}

.contact-profile-mid-right {
  display: flex;
  flex-direction: row;
  width: 35%;
  height: 100%;
}
.messages {
  min-height: 300px;
  max-height: 530px;
  overflow-y: scroll;
  height: 530px;
  overflow-x: hidden;
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  border-bottom-right-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  border: 1px solid lightgray;
}
.messagesCtn {
  max-height: 100%;
  height: 80%;
  position: relative;
  flex: 1 1 80%;
}
.loadingChatSpin {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.message-input {
  position: relative;
  height: 50px;
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid lightgray;
  background-color: #fff;
}

.messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
.messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
.messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 5px 5px 5px 5px;
  width: calc(100% - 25px);
  font-size: 0.9em;
}
.messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
.messages ul li.sent img {
  margin: 0 10px 5px 0;
}
.messages ul li.sent p {
  background: $main;
  color: #f5f5f5;
}
.tn-chat__content .messages ul li.replies img {
  float: right;
  margin: 0px 0 0px 10px;
}
.messages ul li.replies p {
  background: $primary;
  color: #fff;
  float: right;
}
.messages ul li img {
  width: 42px;
  border-radius: 50%;
  float: left;
}
.messages ul li p {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  max-width: 300px;
  line-height: 110%;
  font-size: 15px;
  margin-bottom: 0px;
}

.form-input-chat {
  width: 100%;
  height: 100%;
}
.wrap {
  width: 100%;
  height: 100%;
  padding: 8px 5px 8px 20px;
}
.wrap input {
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  float: left;
  border: none;
  width: calc(100% - 55px);
  padding-left: 10px;
  height: 100%;
  font-size: 15px;
  color: #32465a;
  border-radius: 0.8rem;
  background-color: $primary-back;
  margin-right: 5px;
}

.wrap input:focus {
  outline: none;
}

.attachment {
  float: left;
  width: 50px;
  height: 100%;
  padding-top: 3px;
  margin: 0px 5px 1px 0;
  color: $primary;
  opacity: 0.5;
  cursor: pointer;
  text-align: center;
  transition: all ease 0.8s;
  border-radius: 0.8rem;
  &:hover {
    opacity: 1;
    color: #fff;
    background-color: $primary;
  }
}
.wrap button {
  float: left;
  border: none;
  width: 50px;
  height: 100%;
  cursor: pointer;
  background: #fff;
  color: $primary;
  border-radius: 0.8rem;
  margin: auto 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap button:hover {
  background: $primary;
  color: #fff;
}
.wrap button:focus {
  outline: none;
}

.fa {
  font-size: 1.5em !important;
}

small {
  font-size: 60% !important;
}

#dots-image {
  width: 40px;
  border-color: unset !important;
  border: none !important;
}

.goToProjectBtn {
  border: none;
  max-height: content;
}

.sidepanel .profile .wrap .expanded button {
  float: left;
  border: none;
  width: 50%;
  background: $primary-back;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  margin-bottom: 6px;
  border-radius: 3px;
  padding: 7px;
  width: calc(100% - 43px);
}
.chat-is-empty {
  text-align: center;
  font-family: $main-font;
  height: 100%;
  padding-top: 60px;
  border: 1px solid lightgray;
  border-radius: 0.8rem;
  background-color: #fff;
}
.chat-is-empty-second-text {
  margin-top: 70px;
}

.contactss__chat-button {
  @media screen and (min-width: $mobile-large) and (max-width: $desktop-4k-xlarge) {
    display: none;
  }
}

.contactss__drawer {
  @media screen and (min-width: $mobile-large) and (max-width: $desktop-4k-xlarge) {
    display: none;
  }
}
