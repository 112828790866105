.link-element__container {
  margin-bottom: 20px;
}

.link-element__submited {
  border: 0;
  font-size: 25px;
  color: black;
  display: flex;
  i {
    margin: auto;
  }
  p {
    margin: auto 10px;
  }
}

.link-element__briefcase {
  border: 0;
  font-size: 18px;
  color: black;
  display: flex;
  i {
    margin: auto;
  }
  p {
    margin: auto 10px;
  }
}

.link-element__button {
  padding: 0;
  border: 0;
  font-size: 14px;
  display: flex;
  i {
    margin: auto 5px;
  }
  p {
    margin: auto;
  }
}
