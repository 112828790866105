@import '@sass/app.scss';

.container {
  width: 100%;
  display: flex;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  padding: 5em 10px 0px 10px;
}

.imgContainer {
  display: flex;
  img {
    width: 500px;
    margin: 20px auto -20px auto;
    @media screen and (max-width: $mobile-xlarge) {
      width: 300px;
    }
  }
}

.mainPosts {
  display: flex;
}

.primaryPost {
  flex: 2;
  margin-right: 20px;
  @media screen and (max-width: $desktop-xsmall) {
    margin-right: 0px;
  }
}
.secondaryPosts {
  flex: 1;
  @media screen and (max-width: $desktop-xsmall) {
    display: none;
  }
}
