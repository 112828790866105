.content {
  padding: 20px;
}
.detail {
  padding: 20px;
}

.row {
  display: flex;
}

.row p:first-child {
  flex: 1;
}

.row p:last-child {
  flex: 2;
}
