@import '@sass/app.scss';
.section1__style {
  height: 650px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-color: var(--background-color-light);
  padding-top: 100px;
}
.section1__container {
  max-width: var(--max-width-layout);
  width: 95%;
  height: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttons__container {
  margin-top: 15px;
  display: flex;
  gap: 12px;
  button {
    font-size: 16px !important;
    width: 250px !important;
    font-weight: bold !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
.section1__left {
  width: 60%;
}
.section1__right {
  flex: 0 1 400px;
}
.mobile__image {
  display: none;
}
.landing__title-desktop {
  display: block;
}
.landing__title-mobile {
  display: none;
}
@media screen and (max-width: $mobile-large) {
  .landing__title-desktop {
    display: none;
  }
  .landing__title-mobile {
    display: block;
  }
}
@media screen and (max-width: $desktop-small) {
  .section1__style {
    height: 70em;
    padding-top: 50px;
    text-align: center;
  }
  .section1__container {
    flex-direction: column;
    height: fit-content;
  }
  .section1__right {
    display: none;
  }
  .mobile__image {
    display: flex;
    justify-content: center;
    width: 400px;
    margin: 30px auto;
  }
}
@media screen and (max-width: $mobile-large) {
  .section1__left {
    width: 90%;
  }
}
@media screen and (max-width: $mobile-large) {
  .mobile__image {
    width: 100%;
  }
  .freelancer__image {
    width: 80%;
  }
}
.blur {
  animation: blur 0.5s linear forwards;
  animation-delay: 0s;
}
.delay {
  animation-delay: 0s;
}
@keyframes blur {
  to {
    filter: blur(5px);
  }
}
