@import '@sass/app.scss';

.container {
  background-color: white;
  box-shadow: 0px 8px 10px -15px rgb(119, 119, 119);
  animation: moveInTop 0.5s ease-out 0.2s;
  animation-fill-mode: backwards;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 99;
  padding: 5px 20px;
  @media screen and (max-width: $mobile-xmedium) {
    padding: 5px 10px;
  }
}

.mobile {
  margin: auto 0px;
  display: none;
  background-color: #ebebeb;
  color: #3383ff;
  border-radius: 50%;
  padding: 6px;

  cursor: pointer;
  svg {
    margin: auto;
  }

  @media screen and (max-width: $mobile-xmedium) {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  @media screen and (max-width: $desktop-small) {
    display: flex;
  }
}

.logoContainer {
  padding: 5px;
  cursor: pointer;
  display: flex;
  @media screen and (max-width: $desktop-small) {
    width: 100%;
  }
  img {
    width: 200px;
    max-height: 50px;
    @media screen and (max-width: $desktop-small) {
      width: 150px;
      margin: auto;
    }
    @media screen and (max-width: $mobile-xmedium) {
      width: 100px;
      margin: auto;
    }
  }
}

.linksContainer {
  display: flex;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}

.loginContainer {
  display: flex;
  margin-right: 5px;
  margin-left: auto;
}
.logoBlog {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  img {
    margin-top: 10px;
    width: 200px;
    height: 50px;
    object-fit: contain;
  }

  @media screen and (max-width: $desktop-small) {
    img {
      width: 150px;
    }
  }
  @media screen and (max-width: $mobile-xmedium) {
    img {
      width: 100px;
    }
  }
}
