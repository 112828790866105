@import '@sass/app.scss';

.my-links__container {
  background-color: white;
  border-radius: 0.8rem;

  margin-bottom: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.my-links__content {
  padding: 20px;
}

.my-links__header {
  width: 100%;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  height: 50px;
  background-color: $main;
}
.my-links__row {
  margin-top: 10px;
}

#my-links__briefcase {
  font-size: 15px;
}
