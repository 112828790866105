@import '@sass/breakpoints.scss';

.howitworks__steps {
  display: flex;
  flex-direction: row;
  margin: 50px 0px;
  width: 100%;
  height: 354.922px;
  @media screen and (max-width: $desktop-small) {
    flex-direction: column !important;
    text-align: center;
    gap: 5rem;
    margin: auto;
    height: auto;
  }
}
.howitworks__item {
  display: flex;
  align-items: center;
}

.howitworks__content {
  display: flex;
  flex-direction: row;
  width: 50%;
  @media screen and (max-width: $desktop-small) {
    margin: 70px 0px !important;
    width: 100%;
    justify-content: center;
  }
}
.howitworks__content-inner {
  flex: 0 1 550px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $desktop-small) {
    padding: 0px 0px !important;
  }
}
.howitworks__graphic {
  overflow: hidden;
  @media screen and (min-width: $mobile-large) and (max-width: $desktop-small) {
    height: 150px !important;
    width: 150px !important;
  }
}
.howitworks__number {
  margin-top: 10px;
}

.howitworks__image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  @media screen and (max-width: $desktop-small) {
    width: 100%;
  }
}
.howitworks__banner {
  @media screen and (max-width: $mobile-large) {
    display: none;
  }
}
