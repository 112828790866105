@import '@sass/app.scss';

.container {
  width: 100%;
  padding: 0px;
  margin: auto;
  max-width: 1160px;
  @media screen and (max-width: $mobile-xlarge) {
    padding: 10px;
  }
}

.postsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.postCard {
  width: 32.15%;
  @media screen and (max-width: $desktop-medium) {
    width: 48.5%;
  }
  @media screen and (max-width: $mobile-xlarge) {
    width: 100%;
  }
}
