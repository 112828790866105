.bank-data__container {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: auto;
  padding: 15px;
  width: 100%;
  max-width: 320px;
}
.bank-data__row {
  display: flex;
  margin-left: 40px;
}
.bank-data__confirmation {
  display: flex;
  margin-left: -40px;
  p {
    margin: auto;
  }
}

.bank-data__copy-container {
  display: flex;
  .clipboard-button {
    margin-left: auto;
    border: none;
  }
}
