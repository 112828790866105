@import '@sass/app.scss';

.greenHeader {
  background-color: $secondary;
  height: 30px;
  width: 100%;
  border-top-left-radius: $card-radius;
  border-top-right-radius: $card-radius;
  display: flex;
}

.leftSide {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.leftContent {
  display: flex;
  flex-direction: column;
}

.header {
  max-height: 50px;
  display: flex;
  p {
    margin: 10px;
  }
}

.request-card__user-stats {
  margin: 10px;
}
.rightSide {
  flex: 4;
}

.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  margin: 20px 0px 0px 10px;
  @media screen and (max-width: $mobile-xlarge) {
    padding-left: 20px;
    margin: 0px;
  }
}

.categories {
  display: flex;
  flex-direction: column;
}

.buttons {
  margin: auto;
}

.subcategories {
  display: flex;
}
