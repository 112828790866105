@import '@sass/app.scss';

.container {
  border-radius: $card-radius;
  width: 700px;
}

.content {
  .ant-modal-header {
    border-top-left-radius: $card-radius;
    border-top-right-radius: $card-radius;
  }
}

.content {
  max-height: 50vh;
  overflow-y: scroll;
}

.footer {
  display: flex;
  flex-direction: column;
  hr {
    margin-left: 0;
    margin-right: 0;
  }
}
.intellectualProp {
  display: flex;
  p {
    padding-left: 10px;
  }
}
.buttonContainer {
  display: flex;
  button {
    margin: auto;
  }
}
