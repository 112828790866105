/*USER CARD*/
/*-----------------------------------------------------*/
@import '@sass/app.scss';
.user-card-body {
  background-color: #fff;
  border-radius: 0.8rem;
  display: flex;
  max-height: 200px;
  padding: 3%;
  max-width: 500px;
}
.user-card-image-ctn {
  flex: 1 1 30%;
  margin-left: 4%;
  max-width: 90px;
}
.user-card-image {
  border-radius: 50%;
  max-width: 100%;
}
.user-card-data {
  flex: 1 1 70%;
  margin-left: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}
.user-card-data h5 {
  color: $main;
  font-family: $main-font;
}
.user-card-data h6 {
  font-family: $main-font-light;
  font-weight: bolder;
}
