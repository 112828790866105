@import '@sass/app.scss';

.howitworks {
  width: 100%;
  overflow: hidden;
  background-color: white;
}
.howitworks__container {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  @media screen and (max-width: $desktop-small) {
    height: 400px;
  }
}
.howitworks__header {
  max-width: var(--max-width-layout);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $desktop-small) {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
}
.howitworks__buttons {
  width: 400px;
  display: none;
  @media screen and (max-width: $desktop-small) {
    margin-top: 40px;
    display: block;
  }
}
.howitworks__buttons-top {
  width: 400px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
.howitworks__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 1rem;
  @media screen and (max-width: $desktop-small) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
}
.howitworks__right {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
.howitworks__main {
  display: flex;
  max-width: var(--max-width-layout);
  margin: auto;
  height: auto;

  @media screen and (max-width: $mobile-large) {
    flex-direction: column;
  }
  &__last {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 100px;
    align-items: center;
    flex: 2 1 auto;
    position: relative;

    @media screen and (max-width: $mobile-large) {
      padding-top: 30px;
      flex: 2 1 auto;
      width: 90%;
      margin: auto;
      margin-bottom: 100px;
    }
  }
}
.howitworks__graphics-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  white-space: nowrap;
  @media screen and (max-width: $mobile-large) {
    flex-direction: column;
  }
}
.list-item {
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
  color: black;
}
.background__wrapper {
  align-self: center;
  background-color: transparent;
  position: absolute;
  width: 300px;
  height: 2400px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
.background__wrapper__hire {
  align-self: center;
  background-color: transparent;
  position: absolute;
  width: 300px;
  height: 2000px;
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
.project__photos {
  width: 100%;
}
.project__photos-container {
  height: 400px;
  max-width: var(--max-width-layout);
  margin: auto;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $desktop-small) {
    max-width: 100%;
    width: 100%;
    height: auto !important;
  }
}
.project__photos-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto;
}
.project__photos-right {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $desktop-small) {
    width: 100%;
    height: auto;
  }
}
.project__photo-first {
  z-index: 0;
  margin-right: -50px;
}
.project__photo-second {
  z-index: 1;
}
@media screen and (max-width: $desktop-small) {
  .project__photos-container {
    flex-direction: column;
    height: 800px;
    align-items: center;
    text-align: center;
  }
  .project__photos-left {
    width: 80%;
    align-items: center;
    margin: 5% auto 5%;
    text-align: center;
  }
  .project__photos-right {
    width: 80%;
    margin: 5% auto 5%;
  }
}
.title__header {
  @media screen and (max-width: $desktop-small) {
    display: none;
  }
}
.mobile__header {
  display: none;
  @media screen and (max-width: $desktop-small) {
    display: block;
  }
}
.icons__content {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $desktop-medium) {
    height: 700px;
  }
}
.icons__container {
  max-width: var(--max-width-layout);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: $desktop-medium) {
    flex-direction: column;
  }
}
.faq__section {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;

  @media screen and (max-width: $mobile-xmedium) {
    text-align: center;
  }
}
.faq__container {
  max-width: var(--max-width-layout);
  padding: 40px 50px;
  width: 90%;
  margin: auto;
  border-radius: 3rem;
  height: fit-content;
  @media screen and (max-width: $desktop-small) {
    text-align: center;
  }
}
.faq__content {
  margin-top: 40px;
  text-align: left;
}
.faq__list {
  margin-bottom: 20px;
}
.button__faq {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.switch-container {
  border: 2px solid white;
}
