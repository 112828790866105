.loading-spin-container-fullwidth {
  height: 100vh;
  width: 100vw;
  max-width: 400px;
  max-height: 400px;
  margin: auto;
  background-color: var(--background-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
