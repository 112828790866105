.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.functionalities {
  .item {
    border-radius: 1rem;
    border: 1px solid rgb(217, 216, 216);
    width: 130px;
    height: 110px;
    display: flex;
    margin: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px;
    img {
      width: 70px;
      margin: auto;
    }
    p {
      margin: auto;
    }
  }
}

.integrations {
  margin-top: 10px;
  .item {
    border-radius: 1rem;
    border: 1px solid rgb(217, 216, 216);
    max-width: 120px;
    height: 80px;
    display: flex;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px;
    margin: 5px auto;
    flex: 45%;
    img {
      max-width: 90px;
      max-height: 50px;
      margin: auto;
    }
  }
}
