.notifications__container {
  background-color: white;
  border-radius: 0.8rem;
  padding: 20px;
}

.notifications__pagination {
  display: flex;
  .ant-pagination {
    margin: auto;
  }
}
