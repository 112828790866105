@import '@sass/app.scss';

.messages-dropdown__unread-alert {
  background-color: $warning;
  border-radius: 50%;
  position: absolute;
  font-size: 13px;
  font-family: $main-font;
  color: $secondary-back;
  padding: 1px;
  height: 20px;
  width: 20px;
  top: -5px;
  right: -5px;
  @media screen and (max-width: $mobile-xmedium) {
    font-size: 10px;
    height: 15px;
    width: 15px;
  }
}

.messages-dropdown__container {
  position: absolute;
  top: 50px;
  width: 330px;
  transform: translateX(-75%);
  background-color: $secondary-back;
  overflow: hidden;
  border: 1px solid rgb(201, 199, 199);
  border-radius: $card-radius;
  z-index: 3;
  padding: 5px;
  h3 {
    margin: 10px 0px 0px 10px;
  }
  hr {
    margin-bottom: 0px;
  }
}
.dropdown-icon-container {
  position: relative;
  background-color: #ebebeb;
  border-radius: 50%;
  svg {
    padding: 2px;
  }
}

.messages-dropdown__button {
  list-style-type: none;
  margin: auto 10px;
  position: relative;
  .msg-icon-container {
    margin: auto;
    border-radius: 50%;
    background-color: #ebebeb;
    height: 40px;
    width: 40px;
    display: flex;
    position: relative;

    svg {
      margin: auto;
      color: #3383ff;
    }
    @media screen and (max-width: $mobile-xmedium) {
      width: 30px;
      height: 30px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.messages-dropdown__content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-height: 200px;
  max-height: 55vh;
  width: 100%;
  img {
    width: 60%;
    margin: 10px auto;
  }
}

.messages-dropdown__content::-webkit-scrollbar {
  display: none;
}

.notification__close-ctn {
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: var(--popup-background-color);
}
