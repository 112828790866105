@import '@sass/app.scss';

.project-files {
  display: flex;
  column-gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 35vh;
  overflow-y: auto;
  margin: auto;
  width: 100%;
}

.project-files__no-files-img {
  height: 100px;
  margin: auto;
}
