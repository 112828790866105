@import '@sass/app.scss';

.upload-image-button-container {
  display: flex;
}

.choose-avatar-button {
  flex: 1;
  text-align: center;
}

.upload-button-container {
  flex: 1;
  text-align: center;
}

.categories-container {
  display: flex;
  flex-wrap: wrap;
}

.category-container {
  width: 50%;
  margin: 1px;
  padding: 0 auto;
  flex: 1;
  align-content: center;
}

.avatar-selected-azul {
  background-color: $main !important;
  color: $secondary-back !important;
}

.avatar-selected-rojo {
  background-color: #e73d36 !important;
  color: #e73d36 !important;
}

.avatar-selected-verde {
  background-color: #02d69f !important;
  color: #02d69f !important;
}

.avatar-selected-amarillo {
  background-color: #fadd79 !important;
  color: #fadd79 !important;
}

.choose-category-label-text {
  font-family: $main-font;
  font-size: 20px;
  color: $main;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 12px 0;
}

.avatar-image-modal {
  @include tn-btn;
  font-family: 'Futura Light bt';
  border: transparent;
  border-radius: 0.8rem;
  letter-spacing: 1px;
  align-content: center;
  justify-content: center;
  color: black;
  background-color: #fff;
  font-family: 'Futura Light bt';
  font-size: 13px;
  font-weight: normal;
  border-color: #f5f5f5;
  width: 111px;
  height: 70px;
  transition: background-color 300ms, color 300ms;
  height: 130px;
  margin: 3px;
  &:hover {
    background-color: #f5f5f5;
    opacity: 0.6;
    color: $secondary-back;
  }
  &:active {
    transform: translateY(1px);
  }
}

.image-container {
  width: 100%;
  margin: 4px;
  padding: 0 auto;
}

.edit-picture-title {
  letter-spacing: 1px;
  margin-top: 20px;
  align-content: center;
}

.canvas-hided {
  display: none;
}

.crop-image-modal {
  width: 100%;
  margin: 0px;
  padding: 0 auto;
}

.crop-image-modal div img {
  width: 1000px;
}

@media screen and (max-width: $mobile-large) {
  .ant-modal-centered .ant-modal {
    max-width: 100%;
    width: 100% !important;
  }
}
