@import '@sass/app.scss';

.request__form-scroll::-webkit-scrollbar {
  display: none;
}

.request__categories {
  display: flex;
  flex-direction: row;
}

.request__submit-button {
  align-content: center;
  margin: 20px auto;
  text-align: center;
}

.request__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.request__blue-background {
  background-color: $main;
  height: 60px;
}

.request__header {
  padding: 5px 24px;
}

.request__content {
  padding: 0px 24px;
  flex: 1;
  overflow: auto;
  height: auto;
}
