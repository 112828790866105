#dots-image {
  height: 10px;
  border-color: unset !important;
  border: none !important;
  color: black;
}
.dropdown-menu-item-button {
  border: none !important;
  color: black;
}
.ant-dropdown-menu {
  border-radius: 0.8rem;
}

.bank-account-card__button {
  margin: -15px 0px 0px auto;
}
