@import '@sass/app.scss';

.container {
  border-radius: 1em !important;
  margin: auto;
  max-width: 1024px !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 10px;
  background-color: white;
  padding: 20px;
  @media screen and (max-width: $mobile-xlarge) {
    margin-right: 0px;
  }
}
.projects {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em 0;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
  textarea {
    height: 150px !important;
  }
}
.formImages {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1em;
  margin-top: 2em;
}
@media (max-width: 407px) {
  .formImages {
    justify-content: center;
  }
}

.container .card {
  width: 200px;
  border-radius: 1em;
  margin: 10px;
  @media (max-width: $mobile-xmedium) {
    margin: 10px auto;
  }
  img {
    cursor: pointer;
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin: 0 auto;
    padding: 1px;
    border-radius: 1em;
  }
  svg {
    &:hover {
      color: #3383ff;
    }
  }
}
.cardbody {
  h2 {
    font-size: 14px !important;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: #3383ff;
    }
  }
  p {
    font-size: 12px !important;
  }
}
.infoContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  @media (max-width: 794px) {
    justify-content: center;
  }
  .infoSkills {
    text-align: justify;
    max-width: 400px;
  }
  .skills {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;
    li {
      background-color: #ccc;
      padding: 0 1em;
      border-radius: 1em;
      p {
        margin: 0 !important;
      }
    }
  }
  .images {
    max-width: 300px;
    width: 100%;
    img {
      cursor: pointer;
      object-fit: cover;
      width: 300px;
      height: 200px;
    }
  }
}

.previewImageContainer {
  position: relative;
  img {
    border-radius: 1em;
    object-fit: cover;
    position: absolute;
    left: 0;
  }
}
.deletePreview {
  color: white;
  background-color: #3383ff;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.1em;
  border-radius: 1em;
  cursor: pointer;
  opacity: calc(80%);

  &:hover {
    opacity: calc(100%);
  }
}
.titleUrl {
  &:hover {
    text-decoration: underline;
    color: #3383ff;
  }
}
