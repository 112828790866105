@import '@sass/app.scss';

.container {
  background-color: white;
  border-radius: 1em;
  padding: 20px;
  display: flex;
  @media screen and (max-width: $mobile-xlarge) {
    flex-direction: column;
  }
}
.left {
  flex: 2;
  padding-right: 10px;
  @media screen and (max-width: $mobile-xlarge) {
    padding-right: 0px;
    margin-bottom: 20px;
  }
}
.right {
  flex: 1;
  border-left: 1px solid #dadbdb;
  padding-left: 10px;
  @media screen and (max-width: $mobile-xlarge) {
    padding-left: 0px;
    border-left: none;
    border-top: 1px solid #dadbdb;
    padding-top: 10px;
  }
}
