@import '@sass/app.scss';
.radio {
  border-radius: 50%;
  background-color: $primary-back;
  width: 20px;
  height: 20px;
  padding: 4px;
}
.container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.selected {
  background-color: $main;
  border-radius: 100%;
  height: 100%;
}
