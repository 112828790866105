@import '@sass/app.scss';

.content {
  padding: 10px;
}

.frameworks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.item {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  width: 100px;
  height: 80px;
  display: flex;
  margin: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 5px;
  margin: 5px auto;
  flex: 45%;
  img {
    max-width: 90px;
    max-height: 50px;
    margin: auto;
  }
}

.empty {
  border-radius: 1rem;
  border: 1px solid rgb(217, 216, 216);
  padding: 10px;
  width: 200px;
  margin: auto;
}
