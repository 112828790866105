.proposal-section__container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.proposal-section__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 10px;
  }
}

.proposal-section__request-breakdown {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0px;
  padding: 15px;

  hr {
    height: 2px;
    width: 80%;
  }
}
