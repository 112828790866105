@import '@sass/app.scss';
.stages-definition__select-nstages {
  overflow: hidden;
  display: flex;
  min-height: 30px;
  margin-top: 20px;
  border-radius: 0.8rem;
  background-color: white;

  :first-child {
    border-radius: 0.8rem 0 0 0.8rem;
  }
  :last-child {
    border-radius: 0 0.8rem 0.8rem 0;
    border-right-width: 0.001rem !important;
  }

  .stages-definition-item {
    flex: 1;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    border: solid 0.001rem #d7d7d7;
    color: #d7d7d7;
    border-right-width: 0;

    label {
      width: 100%;
      height: 100%;
      display: flex;
    }
    span {
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      display: flex;
    }
    input {
      visibility: visible;
      opacity: 0;
    }
  }
}

.stages-definition-item input[type='radio']:checked + span {
  color: $secondary-back;
  background-color: $primary;
  border-radius: 0rem;
}

.stages-definition-item:hover {
  background-color: #f2f2f2;
}
