@import '@sass/app.scss';

@media screen and (max-width: $mobile-large) {
  .search-bar-main-container {
    width: 100%;
  }
}
.search-bar-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 0.8rem;
  margin-right: 10px;
  font-family: $main-font-light;
  font-size: 15px;
  color: black;
  font-weight: normal;
  letter-spacing: 0.5px;
  padding-top: 20px;
  &.is-full-width {
    width: 100%;
  }
}

.search-bar-left-side {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.search-bar-right-side {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
}

.search-bar-categories-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.search-bar-category-container {
  display: inline-block;
  flex: 1;
  padding: 13px 0 0 20px;
}

.search-bar-input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: 0;
  padding: 2px 0 2px 10px;
}
.search-bar-input:focus {
  outline: none !important;
  border-radius: 0.8rem;
  border: none;
}

.search-bar-button {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: background-color 0.3s ease;
  border-top-right-radius: $button-radius;
  border-bottom-right-radius: $button-radius;
}

.search-bar-icon {
  margin-top: 26%;
  color: $main;
}

.search-bar-button:hover {
  background-color: $main;
  svg {
    color: #fff;
  }
}

.search-bar-category-container {
  display: flex;
  align-content: center;
}

.search-bar-type {
  border-radius: 50%;
  background-color: $primary-back;
  height: 15px;
  width: 15px;
  margin: 3px 4px 0 0;
  transition: background-color 0.3s ease;
}

.search-bar-category-container:hover #search-bar-type {
  background-color: $main;
  opacity: 0.9;
}

.search-bar-type-text {
  margin-bottom: 5px;
}

.search-bar-input-container {
  display: flex;
  background-color: $primary-back;
  border-radius: $button-radius;
  max-width: 100%;
  padding-left: 10px;
  opacity: 0.9;
}
