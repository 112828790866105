@import '@sass/app.scss';

.freelancer-request__files-container {
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  background-color: white;
  padding: 10px;
  border-radius: 0.8rem;
  hr {
    margin: 2px;
  }
}

.freelancer-request__chat-container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  min-height: 40vh;
}

.freelancer-request__other-request-wrapper {
  background-color: white;
  max-height: 70vh;
  overflow: auto;
  padding: 10px;
  border-radius: $card-radius;
  margin-bottom: 10px;
  hr {
    margin-top: 2px;
  }
}
.layoutContainer {
  @media screen and (max-width: $desktop-small) {
    padding: 20px;
  }
}
