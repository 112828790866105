@import '@sass/app.scss';

.request-breakdown__container {
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
  padding: 15px;
}
.request-breakdown__header {
  display: flex;
  h4 {
    margin: auto 0px;
  }
  h3 {
    margin: auto 0px;
  }
  .tn-btn {
    margin: auto 0px auto auto;
  }
  .request-breackdown__discount-applied {
    margin: auto 0px auto auto;
    border: 1px solid $success;
    display: flex;
    border-radius: $button-radius;
    padding: 0px 7px;
    p {
      color: green;
      margin: 0px;
      margin-top: 2px;
    }
    svg {
      color: green;
      margin: auto 5px;
      width: 15px;
    }
  }
}

.request-breakdown__row-input {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.request-breakdown__title-input {
  position: relative;
  flex: 4;
  margin: 20px 0px;
}

.request-breakdown__price-input {
  position: relative;
  flex: 2;
  margin: 20px;
  input {
    width: 100%;
    text-align: right;
  }
}

.request-breakdown__days-input {
  position: relative;
  flex: 1;
  margin: 20px 0px;
}

.request-breakdown__row-show {
  display: flex;
  flex-direction: row;
}

.request-breakdown__title-show {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 4;
  svg {
    color: $main;
    margin: auto 5px;
    width: 15px;
  }
  p {
    margin: 0px;
    margin-top: 2px;
  }
}

.request-breakdown__price-show {
  position: relative;
  flex: 2;
  input {
    width: 100%;
    text-align: right;
  }
  p {
    text-align: end;
  }
}
