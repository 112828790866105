.owner {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  img {
    width: 30px;
    margin: auto 5px;
  }
  p {
    margin: auto 5px;
  }
  ul {
    margin-bottom: 5px;
  }
  div {
    font-size: 12px;
  }
}
