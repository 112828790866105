@import '@sass/app.scss';

.my-profile__container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $mobile-xlarge) {
    flex-direction: column;
  }
}

.my-profile__column:first-child {
  flex: 5;
}

.my-profile__column:last-child {
  flex: 3;
  @media screen and (max-width: $mobile-xlarge) {
    margin-top: 10px;
  }
}

.my-profile__row {
  display: flex;
}
