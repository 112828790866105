.container {
  height: 90vh;
  flex-direction: column;
  display: flex;
}

.item {
  padding: 10px;
  border-radius: 0.8rem;
  background-color: white;
  margin: auto;
  width: 100%;
}
