// Main colors
//$main: #19acd0;
$main: #3383ff;
$primary: #3383ff;
$secondary: #00d5a1;
$warning: rgb(238, 50, 50);
$details: lightgray;
$light: #fff;
$success: green;

//Text colors
$primary-text: rgba(0, 0, 0, 0.8);
$details-text: #7e7f80;
$blog-text: rgba(0, 0, 0, 0.7);
$window: #f4f5f7;
$grey-font: #6f6f6f;

// Background colors
$primary-back: rgb(235, 235, 235);
$secondary-back: #fff;
$menu-hover: #dbdbdb;
$popup-back: rgba(128, 128, 128, 0.25);
$box-shadow: 0 7px 10px rgba(153, 153, 153, 0.185);
$box-shadow-border: 0px 0px 20px 1px rgba(15, 15, 15, 0.52);

//Others
$disabled-color: #fad6d6;
$background-color: rgb(235, 235, 235);
$popup-background-color: rgba(128, 128, 128, 0.25);
$evaluation: #fdcc0d;
$secondary-button-border: rgba(0, 0, 0, 0.2);
